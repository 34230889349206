import router from "next/router";

/**
 * Gives a MFE the ability to dictate whether or not navigation to another MFE should occur.
 * This can be useful when an MFE would like to prevent a user from accidentally navigating
 * away from the current page via. the Shell's sidebar while performing some timely activity,
 * such as filling out a large form.
 *
 * The blocking mechanism works by intercepting the `routeChangeStart` event emitted by Next.js
 * and checking if any of the registered interceptors return false. If so, the `emitRouteChangeError`
 * method is called, which invokes `unhandledrejection` on the window object. By calling
 * `preventDefault` on the event, we can prevent Next.js from navigating to the next page.
 *
 * @example
 * useEffect(() => {
 *   const removeInterceptor = navigationController.addInterceptor(nextPath => {
 *     if (formRef.isDirty()) {
 *       return false;
 *     }
 *   });
 *   return removeInterceptor;
 * }, []);
 */
export class NavigationController {
  static routeChangeErrorMsg =
    "MFE aborting route change. Please ignore this error.";

  /**
   * Callback method that is invoked when navigation is blocked.
   * This method emits an error which causes an `unhandledrejection` event to be emitted.
   * This event is then observed by the `suppressExpectedError` method, which prevents
   * the router from navigating to the next page.
   * @see https://nextjs.org/docs/pages/api-reference/functions/use-router#routerevents
   * @private
   */
  static emitRouteChangeError() {
    router.events.emit(
      "routeChangeError",
      NavigationController.routeChangeErrorMsg,
      "",
      {
        shallow: false,
      },
    );
    throw NavigationController.routeChangeErrorMsg;
  }

  /**
   * Callback method that is invoked when there is an unhandled promise rejection during
   * the navigation process. This method check if the error was caused by the
   * `emitRouteChangeError` method and suppresses the error if so.
   * @private
   * @param {*} event
   */
  static suppressExpectedError(event) {
    if (event.reason === NavigationController.routeChangeErrorMsg) {
      event.preventDefault();
    }
  }

  /**
   * @type {((nextRoute) => boolean | undefined)[]} list of active interceptors
   */
  interceptors = [];

  constructor() {
    if (typeof window !== "undefined") {
      window.addEventListener(
        "unhandledrejection",
        NavigationController.suppressExpectedError,
      );
    }
    router.events.on("routeChangeStart", this.handleRouteChangeStart);
  }

  /**
   * Remove event listeners to prevent memory leaks.
   */
  cleanup() {
    if (typeof window !== "undefined") {
      window.removeEventListener(
        "unhandledrejection",
        NavigationController.suppressExpectedError,
      );
    }
    router.events.off("routeChangeStart", this.handleRouteChangeStart);
  }

  /**
   * @param {string} nextPath
   * @returns {boolean} - true if navigation should be blocked.
   */
  isNavigationBlocked(nextPath) {
    return (
      this.interceptors.length > 0 &&
      this.interceptors.some((interceptor) => interceptor(nextPath) === false)
    );
  }

  /**
   *
   * @param {(nextRoute) => boolean | undefined} interceptor that returns false if navigation should be blocked.
   * @returns {() => void} - function to remove the interceptor from the list of interceptors.
   */
  addInterceptor(interceptor) {
    this.interceptors.push(interceptor);
    return () => {
      this.interceptors = this.interceptors.filter((i) => i !== interceptor);
    };
  }

  /**
   * callback method that is passed to Next.js router events.
   * Emits an error if navigation is blocked.
   * @private
   * @param {string} nextPath
   */
  handleRouteChangeStart = (nextPath) => {
    if (this.isNavigationBlocked(nextPath)) {
      NavigationController.emitRouteChangeError();
    }
  };
}

export const navigationController = new NavigationController();
