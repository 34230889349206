/**
 * Finds the closest matching hrefs for the current route
 * @param {Array<String>} allRoutes A flattened array of all nav hrefs
 * @param {String} currentRoute The current route
 * @returns {Array<String>} An array of matching routes, starting with the closest match
 */
export default function findActiveLinks(allRoutes, currentRoute) {
  const currentRouteSegments = getRouteSegments(currentRoute);
  const activeLinks = [];
  let closestMatchCount = 0;

  if (currentRouteSegments[0] == "impersonations") {
    return ["impersonations"];
  }

  allRoutes.forEach((route) => {
    const routeSegments = getRouteSegments(route);

    // if the routes both match, they tie
    if (route === currentRoute.split("?")[0]) {
      activeLinks.unshift(route);
      closestMatchCount = routeSegments.length;
    }

    const iterations = Math.min(
      currentRouteSegments.length,
      routeSegments.length,
    );

    let matchCount = 0;
    for (let i = 0; i < iterations; i++) {
      if (currentRouteSegments[i] === routeSegments[i]) {
        matchCount++;
      } else {
        break;
      }
    }

    if (matchCount > closestMatchCount) {
      closestMatchCount = matchCount;
      activeLinks.unshift(route);
    }
  });

  return activeLinks;
}

function getRouteSegments(route = "") {
  return route.split("/").filter(Boolean);
}
