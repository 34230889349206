import {useEffect, useState} from "react";

import {FrozenWindowVariable} from "utils/FrozenWindowVariable";

/**
 * WARNING: DO NOT put any sensitive data on `window`.
 *
 * @param {String} key name of property to set on `window`
 * @param {any} value value of the property to set on `window`
 * @param {Boolean} isReady determines if the variable is ready for initialization on `window`
 * @returns {FrozenWindowVariable}
 */
export default function useFrozenWindowVariable(key, value, isReady = true) {
  const [frozenWindowVariable, setFrozenWindowVariable] = useState(() => {
    if (isReady) {
      return new FrozenWindowVariable(key, value);
    } else {
      return null;
    }
  });

  useEffect(() => {
    if (isReady && !frozenWindowVariable) {
      setFrozenWindowVariable(new FrozenWindowVariable(key, value));
    }
  }, [isReady, key, frozenWindowVariable, value]);

  return frozenWindowVariable;
}
