import {getLocalAuthToken} from "./auth";

import type {InternalAxiosRequestConfig} from "axios";

/**
 * Axios request interceptor adding the Authorization header with JWT if the JWT is available.
 * @param config Config provide from each axios call.
 * @returns The extended config object to be used by Axios.
 */
export function axiosRequestAuthorizationHeaderInterceptor(
  config: InternalAxiosRequestConfig,
) {
  const authToken = getLocalAuthToken();

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  return config;
}
