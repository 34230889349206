import type {RumEvent, RumEventDomainContext} from "@datadog/browser-rum-core";
import type {LogsEvent} from "@datadog/browser-logs";
import type {RumActionEventDomainContext} from "@datadog/browser-rum";
import {datadogRum} from "@datadog/browser-rum";
import {datadogLogs} from "@datadog/browser-logs";

import {logger} from "utils/logger";
import {redactObject} from "utils/logger/redact";

export const DD_API_KEY_PARAM = "dd-api-key";

export function datadogProxyFn({
  path,
  parameters,
}: {
  path: string;
  parameters: string;
}) {
  const proxyEndpoint = "/api/telemetry/proxy"; // TODO move this to process.env.DATADOG_PROXY (requires vercel configuration update)

  // delete client token (fake value anyway, real value added by the proxy backend)
  const parsedParameters = new URLSearchParams(parameters);
  parsedParameters.delete(DD_API_KEY_PARAM);

  // encode original parameters to circumvent ad-block pattern matching
  const encodedParams = encodeURIComponent(parsedParameters.toString());

  // encode path
  const encodedPath = encodeURIComponent(path);

  return `${proxyEndpoint}?path=${encodedPath}&params=${encodedParams}`;
}

export function getDatadogInitConfiguration() {
  // Application id must be available on frontend, it's added to individual events by the SDK.
  const applicationId = process?.env?.DATADOG_APPLICATION_ID;

  // Real client token is added in the proxy backend, but the SDK requires some sting in the init.
  // DO NOT ADD THE REAL CLIENT TOKEN HERE
  const clientToken = "SECRET";

  const env =
    process.env.NODE_ENV === "development"
      ? "dev"
      : process.env.REGION
        ? process.env.REGION.toLowerCase()
        : undefined;

  const isVercelPreview =
    !!process.env.VERCEL_CUSTOM_PREVIEW_SUFFIX &&
    location.origin.endsWith(process.env.VERCEL_CUSTOM_PREVIEW_SUFFIX);

  const allowedEnvs = ["dev", "int", "int-eks"];
  const isEnvAllowed: boolean =
    !!env && !isVercelPreview && allowedEnvs.includes(env);

  const isEnabled =
    applicationId && // is configured
    isEnvAllowed; // env is allowed

  const version = process.env.VERSION;

  const service = "xdr-ui";

  const proxy = datadogProxyFn;

  return {
    applicationId,
    clientToken,
    env,
    isEnabled,
    version,
    service,
    proxy,
  };
}

export function redactDatadogRumEvent(
  event: RumEvent,
  context: RumEventDomainContext,
): boolean {
  // redact the event using logging utils
  redactObject(event);

  // redact action names
  if (event.type === "action") {
    const {action} = event;

    if (action.target?.name) {
      // replace action target name with tag name + class name
      // to avoid sending text content from clicked elements
      const actionContext = context as RumActionEventDomainContext;

      const eventTarget =
        actionContext.events && actionContext.events?.length > 0
          ? actionContext.events[0].target
          : null;

      if (eventTarget instanceof Element) {
        const tagName = eventTarget.tagName.toLowerCase();
        const tagClass = eventTarget.classList[0];
        action.target.name = `${tagName}${tagClass ? "." + tagClass : ""}`;
      } else {
        action.target.name = "<REDACTED>";
        logger.warn(
          "Could not determine a good target.name for datadog action",
        );
      }
    }
  }

  return true;
}

export function redactDatadogLogEvent(event: LogsEvent): boolean {
  // redact the event using logging utils
  redactObject(event);

  return true;
}

export function initDatadogRum() {
  const isInitialized = !!window.DD_RUM?.getInitConfiguration(); // prevents re-initialization on hot-reload

  if (!isInitialized) {
    const {
      isEnabled,
      applicationId,
      clientToken,
      env,
      version,
      service,
      proxy,
    } = getDatadogInitConfiguration();

    // Do not mask in INT and TEST, but mask everywhere else.
    // DD is not enabled anywhere else than INT, but this is a safety measure for the time we are enabling it.
    const defaultPrivacyLevel =
      process.env.REGION?.startsWith("INT") || process.env.REGION === "TEST"
        ? "allow"
        : "mask";

    if (
      isEnabled && // DD is enabled
      applicationId && // applicationId is set
      env // env is set
    ) {
      datadogRum.init({
        applicationId,
        clientToken,
        env,
        version,
        service,
        proxy,
        allowedTracingUrls: ["https://conure.int.iroh.site"],
        beforeSend: redactDatadogRumEvent,
        defaultPrivacyLevel,
        sessionReplaySampleRate: 20,
        sessionSampleRate: 100,
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
        trackViewsManually: true,
      });

      datadogRum.startSessionReplayRecording();
    }
  }
}

export function initDatadogLogs() {
  const isInitialized = !!window.DD_LOGS?.getInitConfiguration(); // prevents re-initialization on hot-reload

  if (!isInitialized) {
    const {isEnabled, clientToken, env, version, service, proxy} =
      getDatadogInitConfiguration();

    if (
      isEnabled && // DD is enabled
      env // env is set
    ) {
      datadogLogs.init({
        clientToken,
        env,
        version,
        service,
        proxy,
        beforeSend: redactDatadogLogEvent,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
      });
    }
  }
}

export function startDatadogView(serviceName: string) {
  datadogRum.startView({
    // Note: this is a bit strange, as it overrides the "xdr-ui" service name from the init.
    // However this does help with visualizing the data in the datadog dashboard and does not affect the logs.
    // The documentation suggests to set "name" attribute as the view name, however if not set it defaults
    // to the url pattern, which is more useful than just static page "name".
    service: serviceName,
  });
  // using the "app" tag instead of overriding the service name for logs
  datadogLogs.setGlobalContextProperty("app", serviceName);
}
