/**
 * Flattens the hrefs of a navigation tree into a single array.
 * @param {Array<Object} navTree The current navigation tree
 * @returns {Array<String>} A flat array of all hrefs in the navTree
 */
export default function getNavTreeLinks(navTree = []) {
  return navTree.reduce((acc, {href, subNavigation}) => {
    const subNavHrefs = subNavigation ? getNavTreeLinks(subNavigation) : [];
    acc.push(href, ...subNavHrefs);
    return acc;
  }, []);
}
