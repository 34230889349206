import {createContext, useEffect, useState} from "react";

import Spinner from "components/Spinner";
import windowUtils from "utils/window";
import {clearConfig, getConfig} from "utils/config";
import {logger} from "utils/logger";

import type {RuntimeConfig} from "types/runtimeConfig";

type Config = RuntimeConfig | Record<string, never>;

interface ConfigContext {
  config: Config;
  isLoading: boolean;
}

export const ConfigContext = createContext<ConfigContext>({
  config: {},
  isLoading: true,
});

export default function ConfigProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [config, setConfig] = useState<Config>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    logger.info(`Commit Hash: ${process.env.COMMIT_HASH}`, {
      hash: process.env.COMMIT_HASH,
    });
  }, []);

  useEffect(() => {
    window.__SHELL__ = {utils: windowUtils};

    getConfig()
      .then((config) => {
        setConfig(config);
      })
      .finally(() => setLoading(false));

    return () => {
      // when unmounting, clear configPromise singleton,
      // so that it loads again when the app is mounting again
      clearConfig();
    };
  }, []);

  return (
    <ConfigContext.Provider value={{config, isLoading: loading}}>
      {loading ? <Spinner /> : children}
    </ConfigContext.Provider>
  );
}
