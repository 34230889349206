/* eslint-disable import/order */
import {createContext, useEffect, useState} from "react";

import darkCss from "!!raw-loader!@ciscodesignsystems/cds-magnetic-theme-web/css/dist/token-theme-dark-variables.css";
import lightCss from "!!raw-loader!@ciscodesignsystems/cds-magnetic-theme-web/css/dist/token-theme-light-variables.css";

const LS_THEME_KEY = "persist-magna-react-theme";

export const ThemeContext = createContext({
  theme: undefined,
  setTheme: () => {},
  toggleTheme: () => {},
});

export default function ThemeProvider({children}) {
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem(LS_THEME_KEY);
    applyColorScheme(savedTheme);
    return (
      savedTheme ||
      (window.matchMedia?.("(prefers-color-scheme: dark)").matches && "dusk") ||
      "default"
    );
  });

  // Mirrors user's OS theme changes
  useEffect(() => {
    function handleOsThemeChange(ev) {
      if (ev.matches) {
        setTheme("dusk");
      } else {
        setTheme("default");
      }
    }

    const darkModeMQ = window.matchMedia("(prefers-color-scheme: dark)");
    darkModeMQ.addEventListener("change", handleOsThemeChange);

    return () => {
      darkModeMQ.removeEventListener("change", handleOsThemeChange);
    };
  }, []);

  // Applies the proper classes and save theme to localStorage
  useEffect(() => {
    let switchTimeout = null;

    localStorage.setItem(LS_THEME_KEY, theme);

    document.body.classList.add("theme-is-switching");
    switchTimeout = setTimeout(() => {
      document.body.classList.remove("theme-is-switching");
    }, 1000);

    applyColorScheme(theme);
    applyThemeClasses(theme, [
      document.querySelector("#__next"),
      document.querySelector(".a-app"),
    ]);

    return () => {
      clearTimeout(switchTimeout);
    };
  }, [theme]);

  // Imports the correct CDS theme
  useEffect(() => {
    const themeCss = theme === "dusk" ? darkCss : lightCss;
    setCdsThemeStyles(themeCss);
  }, [theme]);

  // Overrides AApp theme classes when user changes theme
  useEffect(() => {
    const observer = new MutationObserver((mutationRecords) => {
      mutationRecords
        .filter(
          ({type, addedNodes}) =>
            type === "childList" && addedNodes?.length > 0,
        )
        .flatMap(({addedNodes}) => [...addedNodes])
        .forEach((addedNode) => {
          // for AApp nodes, manually alter the theme class
          if (addedNode.classList?.contains("a-app")) {
            applyThemeClasses(theme, [addedNode]);
          }
        });
    });

    // Watch for changes to #content-inner and its children
    observer.observe(document.querySelector("#content-inner"), {
      childList: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [theme]);

  function toggleTheme() {
    setTheme((prevTheme) => (prevTheme !== "dusk" ? "dusk" : "default"));
  }

  return (
    <ThemeContext.Provider value={{setTheme, theme, toggleTheme}}>
      {children}
    </ThemeContext.Provider>
  );
}

function applyColorScheme(theme) {
  const themeVal = theme === "dusk" ? "dark" : "light";
  document.documentElement.setAttribute("data-color-scheme", themeVal);
}

function applyThemeClasses(theme, els) {
  els.forEach((el) => {
    if (!el) return;
    if (theme === "default") {
      el.classList.remove("theme--dusk");
      el.classList.add("theme--default");
    } else {
      el.classList.remove("theme--default");
      el.classList.add("theme--dusk");
    }
  });
}

function setCdsThemeStyles(themeCss) {
  const themeDataAttr = "data-cds-theme-vars";
  const themeStyleEl = document.querySelector(`[${themeDataAttr}]`);

  if (themeStyleEl) {
    themeStyleEl.innerHTML = themeCss;
  } else {
    const newEl = document.createElement("style");
    newEl.setAttribute(themeDataAttr, "");
    newEl.innerHTML = themeCss;
    document.head.appendChild(newEl);
  }
}
