import {logger} from "utils/logger";
const dbName = "impersonationsDatabase";
const storeName = "users";
const indexName = "userUserId";
// indexeddb indexes don't appear to support hyphenated keys like "user-id"
const indexKeyPath = "userId";

function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      // unique key for each object in store
      const objectStore = db.createObjectStore(storeName, {
        autoIncrement: true,
      });
      objectStore.createIndex(indexName, indexKeyPath, {unique: true});
    };

    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => reject(event.target.error);
  });
}

export async function getUsers() {
  const db = await openDatabase();
  const transaction = db.transaction(storeName, "readonly");
  const store = transaction.objectStore(storeName);

  return new Promise((resolve, reject) => {
    const request = store.getAll();

    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => reject(event.target.error);
  });
}

export async function addUser(data) {
  const db = await openDatabase();
  const transaction = db.transaction(storeName, "readwrite");
  const store = transaction.objectStore(storeName);

  return new Promise((resolve, reject) => {
    const request = store.add(data, data.user["user-id"]);

    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => reject(event.target.error);
  });
}

export async function removeUser(userId) {
  const db = await openDatabase();
  const transaction = db.transaction(storeName, "readwrite");
  const store = transaction.objectStore(storeName);
  const index = store.index("userUserId");

  return new Promise((resolve, reject) => {
    const getRequest = index.openKeyCursor(IDBKeyRange.only(userId));
    getRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        const key = cursor.primaryKey;
        const deleteRequest = store.delete(key);

        deleteRequest.onsuccess = () => resolve(true);
        deleteRequest.onerror = (event) => reject(event.target.error);
      } else {
        resolve(false);
      }
    };

    getRequest.onerror = (event) => reject(event.target.error);
  });
}

export async function updateUser(data, userId) {
  const db = await openDatabase();
  const transaction = db.transaction(storeName, "readwrite");
  const store = transaction.objectStore(storeName);
  const index = store.index("userUserId");

  return new Promise((resolve, reject) => {
    const getRequest = index.openKeyCursor(IDBKeyRange.only(userId));
    getRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        const key = cursor.primaryKey;
        const putRequest = store.put(data, key);

        putRequest.onsuccess = () => resolve(true);
        putRequest.onerror = (event) => reject(event.target.error);
      } else {
        resolve(false);
      }
    };

    getRequest.onerror = (event) => reject(event.target.error);
  });
}

/**
 * Checks for User Impersonations Database and whether it has users in it.
 * @returns {Promise<boolean>} The database has users or not.
 */
export async function hasImpersonationsDB() {
  try {
    const databases = await window.indexedDB.databases();
    const dbExists = databases.map((db) => db.name).includes(dbName);
    if (dbExists) {
      const users = await getUsers();
      return users?.length > 0;
    } else {
      return false;
    }
  } catch (err) {
    logger.warn("Error checking for users in database:", err);
    return false;
  }
}
