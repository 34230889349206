// eslint-disable-next-line no-restricted-imports
import axios from "axios";
import camelize from "camelize";

import {getConfig} from "utils/config";
import {axiosRequestAuthorizationHeaderInterceptor} from "utils/axios";

import type {AxiosResponse} from "axios";

/**
 * Camelizes all response data so it's easier to type.
 * @param response The Axios response object.
 * @returns The response object with the data property camelized.
 */
function axiosResponseCamelizeInterceptor({data, ...rest}: AxiosResponse) {
  return {
    data: camelize(data),
    rawData: data,
    ...rest,
  };
}

const irohClient = axios.create({
  timeout: 60_000,
  paramsSerializer: {
    // Serializes arrays in params in IROH's preferred format:
    // {params: {foo: [1, 2]}} => `?foo=1&foo=2` (not `?foo[]=1&foo[]=2`)
    indexes: null,
  },
});

/**
 * Setup interceptors
 */

// Set baseURL based on runtime config
irohClient.interceptors.request.use(async (axiosConfig) => {
  const runtimeConfig = await getConfig();
  axiosConfig.baseURL = runtimeConfig.apis.iroh.base;
  return axiosConfig;
});

// Add JWT token
irohClient.interceptors.request.use(axiosRequestAuthorizationHeaderInterceptor);

// Camelizes response data
irohClient.interceptors.response.use(axiosResponseCamelizeInterceptor);

export default irohClient;
