import {useCallback, useMemo} from "react";

interface NavState {
  isExpanded: boolean;
}

interface UsePersistedNavState {
  navState: NavState;
  persistNavState: (newNavState: NavState) => void;
}

const DEFAULT_NAV_STATE: NavState = {
  isExpanded: false,
} as const;

const LS_KEY = "xdr-shell-nav-state" as const;

export default function usePersistedNavState(): UsePersistedNavState {
  const navStringCache = localStorage.getItem(LS_KEY);

  const navState = useMemo(() => {
    try {
      const parsedCache = navStringCache ? JSON.parse(navStringCache) : {};
      return Object.assign({}, DEFAULT_NAV_STATE, parsedCache);
    } catch (e) {
      return Object.assign({}, DEFAULT_NAV_STATE);
    }
  }, [navStringCache]);

  const persistNavState = useCallback((newNavState: NavState) => {
    localStorage.setItem(LS_KEY, JSON.stringify(newNavState));
  }, []);

  return {
    navState,
    persistNavState,
  };
}
