import {useContext} from "react";

import {ToastContext} from "context/ToastProvider";

/**
 * @typedef {Object} ToastConfig - Configuration for a toast.
 * @property {string} [id] - The unique identifier for the toast. If not provided, a UUID is generated.
 * @property {string} title - The title of the toast.
 * @property {ReactNode} description - The content of the toast.
 * @property {number} [timeout=5000] - The timeout duration for the toast in milliseconds.
 * @property {'info' | 'positive' | 'warning' | 'ai'} [status='info'] - The status of the toast.
 * @property {function} [onToastDismissed] - The callback function to be executed when the toast is dismissed.
 */

/**
 * @typedef {function} EnqueueToastFunction
 * @param {ToastConfig} config - The configuration object for the toast.
 * @returns {string} The unique identifier assigned to the enqueued toast.
 * @throws Will throw an error if the configuration is missing or invalid.
 */

/**
 * @typedef {function} DequeueToastFunction
 * @param {string} id - The unique identifier of the toast to dequeue.
 * @returns {void} Nothing is returned after dequeuing the toast.
 * @throws Will throw an error if the provided ID is invalid or the toast does not exist.
 */

/**
 * A custom React hook for managing toast notifications.
 *
 * @function
 * @name useToast
 * @returns {{
 *   queue: Array<ToastConfig>,
 *   enqueueToast: EnqueueToastFunction,
 *   dequeueToast: DequeueToastFunction
 * }} An object containing the toast management functions and the current toast queue.
 * @example
 * const { queue, enqueueToast, dequeueToast } = useToast();
 *
 * const toastId = enqueueToast({
 *   title: 'Hello',
 *   description: 'Welcome to the world of toasts!',
 * });
 *
 * dequeueToast(toastId);
 */
const useToast = () => useContext(ToastContext);

export default useToast;
