import {useState, useEffect} from "react";

export const useMediaQuery = (media = "max-width: 960px") => {
  const [sizeMatch, setSizeMatch] = useState(false);

  useEffect(() => {
    const mediaQuery = matchMedia(`(${media})`);
    const handleDeviceChange = (e) => {
      e.matches ? setSizeMatch(true) : setSizeMatch(false);
    };
    mediaQuery.addEventListener("change", handleDeviceChange);
    return () => {
      mediaQuery.removeEventListener("change", handleDeviceChange);
    };
  }, [media]);

  return {sizeMatch};
};
