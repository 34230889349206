import {useRouter} from "next/router";
import {useEffect, useRef} from "react";
import _debounce from "lodash.debounce";

import {trackClick, trackNavigation, trackViewportSize} from "utils/telemetry";
import {configureTelemetry, setCommonMetadata} from "utils/telemetryQueue";

/**
 * Adds listeners for certain telemetry events and Datadog.
 */
const useTelemetry = () => {
  const {events} = useRouter();

  useEffect(() => {
    if (typeof window !== "undefined") {
      configureTelemetry();
      setCommonMetadata({
        app: window.__SHELL__.application!,
      });
    }
  }, []);

  // Track click events
  useEffect(() => {
    window.addEventListener("click", trackClick);
    return () => {
      window.removeEventListener("click", trackClick);
    };
  }, []);

  const prevUrl = useRef<string>();

  // Track first load url
  useEffect(() => {
    trackNavigation({firstLoad: "true", url: location.href});
    prevUrl.current = location.href;
  }, []);

  // Track route changes
  useEffect(() => {
    const routeChangeHandler = () => {
      trackNavigation({
        firstLoad: "false",
        url: location.href,
        prevUrl: prevUrl.current,
      });
      prevUrl.current = location.href;
    };
    events.on("routeChangeComplete", routeChangeHandler);
    return () => {
      events.off("routeChangeComplete", routeChangeHandler);
    };
  }, [events]);

  useEffect(() => {
    const onResize = _debounce(trackViewportSize, 1000);
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("reset", onResize);
    };
  }, []);
};

export default useTelemetry;
