import {Check} from "phosphor-react";
import {useMemo, useState, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {CDSButton} from "@ciscodesignsystems/cds-react-button";
import {CDSTextInput} from "@ciscodesignsystems/cds-react-text-input";

import {logger} from "utils/logger";

import useAuth from "../../hooks/useAuth";
import {
  getProfileInfo,
  postUserTokens,
  setAuthToken,
  setRefreshToken,
} from "../../utils/auth";

import type {Account} from "context/AuthProvider";

import styles from "./TenantMenu.module.scss";

export default function TenantSwitcher() {
  const {t} = useTranslation();
  const {
    user: {lastLoggedAt},
    org: {name: orgName},
    accounts,
  } = useAuth();
  const xdrAccountsList = useMemo(
    () =>
      accounts.filter(({org = {}, user, stats}) => {
        const userCanAccessOrg =
          user?.role === "admin" || stats?.allowAllRoleToLogin;
        const orgHasAccessToXdr =
          org.enterpriseId ||
          org.additionalScopes?.includes("cisco/feature-flag/xdr");
        const orgHasAccessToSecureClient = org.mainApp === "sc";
        return (
          userCanAccessOrg && (orgHasAccessToXdr || orgHasAccessToSecureClient)
        );
      }),
    [accounts],
  );
  const [searchValue, setSearchValue] = useState("");
  const [filteredAccountList, setFilteredOrgList] = useState(xdrAccountsList);
  const [showSelectedOrg, setShowSelectedOrg] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  const filterOnSearch = (searchTerm: string) => {
    const filteredAccounts = xdrAccountsList.filter((account) =>
      account.org?.name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase().trim()),
    );
    const includesSelectedOrg = orgName
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase().trim());
    setFilteredOrgList(filteredAccounts);
    setShowSelectedOrg(Boolean(includesSelectedOrg));
  };

  const callSwitchTenant = (tenant: Account) => {
    if (!isSubmitting) {
      postUserTokens(tenant.user!.userId!)
        .then((response) => {
          setAuthToken(response.data.accessToken);
          setRefreshToken(response.data.refreshToken);
          return getProfileInfo();
        })
        .then(() => {
          window.location.reload();
        })
        .catch((error) =>
          logger.error("Failed to switch tenant", {
            text: error?.data ? error.data["error_description"] : error,
          }),
        )
        .finally(() => {
          setSubmitting(false);
        });
    }
  };
  const IS_LARGE_ACCOUNT_LIST = xdrAccountsList.length > 6;

  /**
   * Formats provided date to human-readable representation in user's local timezone.
   */
  const formatLastLoginDate = useCallback((isoDate: string | undefined) => {
    if (typeof isoDate === "undefined") {
      // isoDate = `undefined` means the user has never logged in. Return "never".
      return "never";
    }
    const date = new Date(isoDate);

    if (isNaN(date.getTime())) {
      // isoDate = `undefined` means the user has never logged in. Return "never".
      return "never";
    }

    // Format & return valid date string.
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }, []);

  return (
    <div style={{maxWidth: "280px"}}>
      {IS_LARGE_ACCOUNT_LIST && (
        <CDSTextInput
          className={styles.textInputWrapper}
          onChange={(e) => {
            setSearchValue(e.target.value);
            filterOnSearch(e.target.value);
          }}
          onClear={() => {
            setFilteredOrgList(xdrAccountsList);
            setSearchValue("");
          }}
          placeholder={t("app.tenantMenu.searchOrganizations")}
          size="md"
          value={searchValue}
        />
      )}
      <div
        style={{
          maxHeight: IS_LARGE_ACCOUNT_LIST ? "235px" : undefined,
          marginRight: "-15px",
          paddingRight: "15px",
          overflowY: "auto",
        }}
      >
        {!!showSelectedOrg && (
          <div
            style={{
              paddingTop: "8px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {orgName}
              </p>
              <p
                style={{
                  margin: "0",
                  fontSize: "14px",
                }}
              >
                {t("app.tenantMenu.lastLoginAt", {
                  lastLoginDate: formatLastLoginDate(lastLoggedAt?.[0]),
                })}
              </p>
            </div>
            {xdrAccountsList && (
              <Check
                className="blue-text-color"
                size={"16"}
                style={{alignSelf: "center"}}
              />
            )}
          </div>
        )}
        {filteredAccountList.map((filteredAccount, i) => {
          return (
            <a
              key={i}
              onClick={() => callSwitchTenant(filteredAccount)}
              style={{cursor: "pointer"}}
            >
              <div style={{paddingTop: "16px"}}>
                <CDSButton
                  variant="tertiary"
                  onClick={() => callSwitchTenant(filteredAccount)}
                >
                  {filteredAccount.org?.name}
                </CDSButton>
                <br />
                <p className={styles.lastLogged}>
                  {t("app.tenantMenu.lastLoginAt", {
                    lastLoginDate: formatLastLoginDate(
                      filteredAccount.user?.lastLoggedAt?.[0],
                    ),
                  })}
                </p>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
}
