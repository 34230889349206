import Link from "next/link";
import {useTranslation} from "react-i18next";

import {CDSNav} from "@ciscodesignsystems/cds-react-nav";
import {CDSTag} from "@ciscodesignsystems/cds-react-tag";

import useNav from "hooks/useNav";

import {useTranslateTitle} from "./useTranslatedTitle";

import type {NavItem} from "context/NavProvider";

interface NavMenuProps {
  subNavItems: NonNullable<NavItem["subNavigation"]>;
}
const NavMenu = ({subNavItems}: NavMenuProps) => {
  const transTitle = useTranslateTitle();
  const {t} = useTranslation();
  const {activeSubNav, isUnauthorized} = useNav();

  return (
    <CDSNav.Menu data-testid="main-nav-subnavigation">
      <CDSNav.Menu.Section>
        {subNavItems
          .filter((item) => !!item.title)
          .map((navItem, i) => {
            const {href, beta, rbac} = navItem;
            if (isUnauthorized(rbac)) return null;

            const link = (
              <>
                <span>{transTitle(navItem)}</span>
                {beta && (
                  <CDSTag.Colored>{t("app.navMenu.beta")}</CDSTag.Colored>
                )}
              </>
            );

            return (
              // @ts-expect-error link is expected to be string but is a JSX.Element, to be fixed in the CDS
              <CDSNav.Menu.Link
                key={`sub-nav-${i}`}
                as={Link}
                href={href}
                isSelected={activeSubNav === href}
              >
                {link}
              </CDSNav.Menu.Link>
            );
          })}
      </CDSNav.Menu.Section>
    </CDSNav.Menu>
  );
};

export default NavMenu;
