import classnames from "classnames";
import {CheckCircle, Warning, WarningOctagon} from "phosphor-react";
import {useEffect, useState} from "react";

import {getSystemStatus} from "utils/systemStatus";

import styles from "./SystemStatus.module.scss";

const ICON_COMPONENTS = {
  low: Warning,
  medium: Warning,
  high: WarningOctagon,
};

export default function SystemStatus() {
  const [status, setStatus] = useState("healthy");
  const [severity, setSeverity] = useState();
  const IconComponent = ICON_COMPONENTS[severity] || CheckCircle;

  useEffect(() => {
    getSystemStatus().then(({severity, status}) => {
      setSeverity(severity);
      setStatus(status);
    });
  }, []);

  return (
    <div
      className={classnames(styles.wrapper, styles[status], styles[severity])}
    >
      <div className={styles.icon}>
        <IconComponent size="16px" weight="fill" />
      </div>
      <div className={styles.status}>{status}</div>
    </div>
  );
}
