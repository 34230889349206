import {useRouter} from "next/router";
import {createContext, useCallback, useEffect, useMemo, useState} from "react";

import useConfig from "hooks/useConfig";
import findActiveLinks from "utils/findActiveLinks";
import generateNavTree from "utils/generateNavTree";
import getNavTreeLinks from "utils/getNavTreeLinks";
import useAuth from "hooks/useAuth";
import {useMediaQuery} from "hooks/useMediaQuery";
import usePersistedNavState from "hooks/usePersistedNavState";

import type {Dispatch, SetStateAction} from "react";

export interface NavItem {
  id: string;
  title: string;
  translatedTitleKey?: string;
  href: string;
  subNavigation?: NavItem[];
  icon?: string;
  rbac?: Rbac;
  beta?: boolean;
  xdrOnly?: boolean;
  section?: number;
}

interface Rbac {
  role?: string;
  scopes?: string[];
}

interface SidebarState {
  isExpanded?: boolean;
}

interface NavContextValue {
  activeSubNav?: string;
  activeTopNav: string;
  isExpanded: boolean;
  navTree: NavItem[];
  setSidebarState: Dispatch<SetStateAction<SidebarState>>;
  isUnauthorized: (rbac: Rbac | undefined) => boolean;
}

export const NavContext = createContext<NavContextValue>({
  activeSubNav: "",
  activeTopNav: "",
  isExpanded: false,
  isUnauthorized: () => true,
  navTree: [],
  setSidebarState: () => {},
});

interface NavProviderProps {
  children: React.ReactNode;
}

export default function NavProvider({children}: NavProviderProps) {
  const {asPath} = useRouter();
  const {config} = useConfig();
  const {user} = useAuth();
  const {role: userRole, scopes: userScopes} = user;
  const navTree = useMemo(() => generateNavTree(config), [config]);
  const {sizeMatch} = useMediaQuery();
  const {navState, persistNavState} = usePersistedNavState();
  const [smallSidebarUserState, setSmallSidebarUserState] =
    useState<SidebarState>({});
  const [smallSidebarMediaState, setSmallSidebarMediaState] =
    useState<SidebarState>({});
  const [sidebarUserState, setSidebarUserState] = useState(navState);
  const isExpanded =
    smallSidebarUserState?.isExpanded ??
    smallSidebarMediaState?.isExpanded ??
    sidebarUserState?.isExpanded;

  const [activeSubNav, activeTopNav] = useMemo(() => {
    const navTreeLinks = getNavTreeLinks(navTree);
    const [firstNavMatch = "/control-center", secondNavMatch] = findActiveLinks(
      navTreeLinks,
      asPath,
    );
    if (secondNavMatch) {
      return [firstNavMatch, secondNavMatch];
    } else {
      return [undefined, firstNavMatch];
    }
  }, [asPath, navTree]);

  const isUnauthorized = (rbac: Rbac | undefined) => {
    const requiredRole = rbac?.role ?? userRole;
    const matchesRole = userRole === requiredRole;
    const requiredScopes = rbac?.scopes ?? [];
    const matchesScopes =
      requiredScopes.every((scope) => userScopes?.includes(scope)) ?? true;

    return !matchesRole || !matchesScopes;
  };

  // Force collapse for small screens
  useEffect(() => {
    if (sizeMatch) {
      setSmallSidebarMediaState({isExpanded: false});
    } else {
      setSmallSidebarUserState({});
      setSmallSidebarMediaState({});
    }
  }, [sizeMatch]);

  useEffect(() => {
    persistNavState(sidebarUserState);
  }, [sidebarUserState, persistNavState]);

  const setSidebarState = useCallback(() => {
    if (sizeMatch) {
      // user can still toggle the sidebar on small screens also remember in common user state
      const newIsExpanded = !isExpanded;
      setSmallSidebarUserState({isExpanded: newIsExpanded});
      setSidebarUserState({isExpanded: newIsExpanded});
    } else {
      setSidebarUserState({
        isExpanded: !isExpanded,
      });
    }
  }, [sizeMatch, isExpanded]);

  return (
    <NavContext.Provider
      value={{
        activeSubNav,
        activeTopNav,
        isExpanded,
        navTree,
        setSidebarState,
        isUnauthorized,
      }}
    >
      {children}
    </NavContext.Provider>
  );
}
