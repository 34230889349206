import conureClient from "apis/conure/conureClient";
import {logger} from "utils/logger";

import type {IncidentSearchParams} from "components/AiAssistant/toast/utils";

/**
 * Get count of incidents by search based on provided params (using Conure v2 endpoint).
 * @param params Incident search params.
 * @returns Count of incidents
 */
export async function getIncidentCount(params: IncidentSearchParams) {
  try {
    const response = await conureClient.get("/v2/incident/search/count", {
      params,
    });
    return response.data;
  } catch (error) {
    logger.error("There was a problem fetching count of incidents:", error);
    throw error;
  }
}
