import _isNil from "lodash.isnil";
import _pickBy from "lodash.pickby";

import {logger} from "utils/logger";
import {subscribe} from "eventBus/hooks/aggregatedChannel";
import irohClient from "apis/iroh/irohClient";

import {getLocalAuthToken} from "./auth";

import type {AggregatedSubEvent} from "eventBus/hooks/aggregatedChannel";

export const TELEMETRY_TIMEOUT = 10_000;

type TelemetryBusEvent = Extract<
  AggregatedSubEvent,
  {topic: "publishTelemetry"}
>;

export interface TelemetryMetadata {
  action: string;
  metric?: never;
  [key: string]: unknown;
}

export interface TelemetryEvent<
  M extends TelemetryMetadata = TelemetryMetadata,
> {
  service: string;
  description: string;
  metric?: number;
  state?: unknown;
  metadata: M;
}

class TelemetryQueue {
  private timeout: NodeJS.Timeout | null = null;
  private queue: TelemetryEvent[] = [];
  private static instance: TelemetryQueue;
  private commonMetadata: Record<string, string> = {};

  private constructor() {}

  public setCommonMetadata(metadata: Record<string, string>) {
    this.commonMetadata = metadata;
  }

  public static getInstance(): TelemetryQueue {
    if (!TelemetryQueue.instance) {
      TelemetryQueue.instance = new TelemetryQueue();
    }

    return TelemetryQueue.instance;
  }

  /**
   * Ensures that queued events publish call is scheduled
   * and will be triggered no later than 10s after calling this function
   */
  private setPublishTimeout() {
    if (this.timeout) {
      return;
    }
    this.timeout = setTimeout(() => {
      this.timeout = null;
      try {
        this.publish();
      } catch (err) {
        logger.error("Failed to publish telemetry", err);
        this.setPublishTimeout();
      }
    }, TELEMETRY_TIMEOUT);
  }

  /**
   * Immediately calls the publish endpoint if there are events in the queue.
   * Events are only published if there is an auth token and the app property is set.
   *
   * @see {@link https://visibility.int.iroh.site/iroh/telemetry/} API DOCS
   */
  public async publish(): Promise<void> {
    if (
      this.queue.length > 0 &&
      getLocalAuthToken() &&
      this.commonMetadata.app
    ) {
      try {
        await irohClient.post(
          "/telemetry/publish",
          this.queue.map((event) => ({
            ...event,
            metadata: {
              ...this.commonMetadata,
              ...event.metadata,
            },
            metric: event?.metric ?? 1,
          })),
        );
        this.queue.splice(0, this.queue.length);
      } catch (err) {
        logger.error("Error publishing telemetry:", err);
      }
    }
  }

  /**
   * Add the event to the queue
   */
  public push(event: TelemetryEvent) {
    this.queue.push(event);
    this.setPublishTimeout();
  }
}

export const telemetry = TelemetryQueue.getInstance();

export function publishTelemetry({
  description,
  metadata,
  metric = 1,
  service,
  state,
}: Omit<TelemetryEvent, "metadata"> & {
  metadata: Omit<TelemetryMetadata, "app">;
}) {
  if (!service) {
    throw new Error(
      `Can't publish event without \`service\` key: ${description}`,
    );
  }
  telemetry.push({
    description,
    metadata: _pickBy(metadata, (val) => !_isNil(val)) as TelemetryMetadata,
    metric,
    service,
    state,
  });
}

/**
 * Configure telemetry in a way that queued events are also published
 * before the browser window is closed.
 *
 * It also exposes public interface so the functionality can be called
 * from outside
 */
export function configureTelemetry() {
  const triggerPublish = () => telemetry.publish();
  addEventListener("pagehide", triggerPublish);
  document.documentElement.addEventListener("mouseleave", triggerPublish);

  const subscription = subscribe(
    (e) => publishTelemetry((e as TelemetryBusEvent).payload),
    (e) => e.topic === "publishTelemetry",
  );

  window.__SHELL__ = window.__SHELL__ ?? {};
  window.__SHELL__.utils = window.__SHELL__.utils ?? {};
  window.__SHELL__.utils.telemetry = {
    publish: publishTelemetry,
  };

  return () => {
    removeEventListener("pagehide", triggerPublish);
    document.documentElement.removeEventListener("mouseleave", triggerPublish);
    subscription?.unsubscribe();
  };
}

export function setCommonMetadata(metadata: Record<string, string>) {
  telemetry.setCommonMetadata(metadata);
}
