import {
  isAxiosError,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from "axios";

export const AXIOS_CONFIG_KEYS = [
  "url",
  "method",
  "baseURL",
  "params",
] as const;

export const AXIOS_RESPONSE_KEYS = ["status"] as const;

export interface SanitizedAxiosError extends Error {
  isAxiosError: boolean;
  config: Pick<InternalAxiosRequestConfig, (typeof AXIOS_CONFIG_KEYS)[number]>;
  code?: string;
  response?: Pick<AxiosResponse, (typeof AXIOS_RESPONSE_KEYS)[number]>;
}

/**
 * Redacts AxiosError by constructing a new Error with a subset of information from the original AxiosError.
 * @param arg - The argument to be scrubbed. Not AxiosError values are returned as is.
 */
export function redactAxiosError(arg: unknown) {
  if (!isAxiosError(arg)) {
    return arg;
  }

  const sanitizedError: Partial<SanitizedAxiosError> = new Error(arg.message);

  sanitizedError.name = arg.name;
  sanitizedError.stack = arg.stack;
  sanitizedError.code = arg.code;
  sanitizedError.isAxiosError = arg.isAxiosError;
  sanitizedError.config = AXIOS_CONFIG_KEYS.reduce(
    (acc, key) => {
      if (arg.config && key in arg.config) {
        acc[key] = arg.config[key];
      }
      return acc;
    },
    {} as SanitizedAxiosError["config"],
  );
  if (arg.response) {
    sanitizedError.response = AXIOS_RESPONSE_KEYS.reduce(
      (acc, key) => {
        if (arg.response && key in arg.response) {
          acc[key] = arg.response[key];
        }
        return acc;
      },
      {} as NonNullable<SanitizedAxiosError["response"]>,
    );
  }

  return sanitizedError as SanitizedAxiosError;
}
