import {useCallback, useEffect, useRef} from "react";
import {useRouter} from "next/router";
import {useTranslation} from "react-i18next";

import useToast from "hooks/useToast";
import {getIncidentCount} from "utils/getIncidentCount";
import {getIncidentsBySearch} from "utils/getIncidentsBySearch";
import {publish} from "eventBus/publish";

import AiToastDescription from "./AiToastDescription";
import {filteredIncidentsUrl, incidentSearchParams} from "./utils";

export const NEW_INCIDENTS_TOAST_SEEN_KEY = "aiAssistant.newIncidentsToastSeen";

/**
 * The component displays the AI toast variant upon the Shell app's mount in order to notify the user
 * of any new incidents requiring attention.
 * To display the AI toast, this component calls an API to get the count of filtered incidents based on provided parameters,
 * and if the count is more than 0, it pushes a toast notification to the queue.
 * In case the count equals 1, it makes an additional API call to obtain an incident ID that is used to build
 * the incident detail URL for the AI toast button.
 */
const AiToastIncidents = () => {
  const router = useRouter();
  const {t} = useTranslation();
  const {enqueueToast, dequeueToast} = useToast();
  const toastId = useRef("");
  const newIncidentToastSeen = Boolean(
    sessionStorage.getItem(NEW_INCIDENTS_TOAST_SEEN_KEY),
  );

  const publishEvent = useCallback((count: number, id?: string) => {
    if (count === 1 && id) {
      publish({
        topic: "aiToastViewOneIncidentButtonClicked",
        payload: {
          incidentId: id,
        },
      });
    } else {
      publish({
        topic: "aiToastViewIncidentsButtonClicked",
        payload: undefined,
      });
    }
  }, []);

  const handleButtonClick = useCallback(
    (url: string, count: number, id?: string) => {
      if (!url) return;
      router.push(url, url, {shallow: true}).then(() => {
        dequeueToast(toastId.current);
        publishEvent(count, id);
      });
    },
    [dequeueToast, publishEvent, router],
  );

  const singleIncidentAiToastConfig = useCallback(
    (id: string) => {
      const shortId = id.substring(id.lastIndexOf("/") + 1);
      const incidentDetailUrl = `/incidents/${shortId}/overview`;
      return {
        title: t("ai.toast.newIncident.title"),
        description: (
          <AiToastDescription
            description={t("ai.toast.newIncident.description")}
            buttonText={t("ai.toast.newIncident.viewIncident")}
            dataEvent="viewIncident"
            onButtonClick={() => handleButtonClick(incidentDetailUrl, 1, id)}
          />
        ),
        status: "ai",
      };
    },
    [handleButtonClick, t],
  );

  const multipleIncidentsAiToastConfig = useCallback(
    (count: number) => {
      const countLabel = count > 100 ? "100+" : String(count);
      return {
        title: t("ai.toast.newIncidents.title", {countLabel}),
        description: (
          <AiToastDescription
            description={t("ai.toast.newIncident.description")}
            buttonText={t("ai.toast.newIncident.viewIncident")}
            dataEvent="viewIncidents"
            onButtonClick={() => handleButtonClick(filteredIncidentsUrl, count)}
          />
        ),
        status: "ai",
      };
    },
    [handleButtonClick, t],
  );

  useEffect(
    () => {
      /**
       * Display AI toast notification for all pages when the Shell app mounts.
       * The prompt should only be shown once per login session
       */
      if (!newIncidentToastSeen) {
        getIncidentCount(incidentSearchParams).then((count) => {
          if (count > 0) {
            if (count === 1) {
              // Call "/v2/incident/search" API to get incident ID in order to build incident detail URL for AI toast button
              getIncidentsBySearch(incidentSearchParams, 1).then(
                (incidents) => {
                  if (incidents[0].id) {
                    toastId.current = enqueueToast(
                      singleIncidentAiToastConfig(incidents[0].id),
                    );
                  }
                },
              );
            } else {
              toastId.current = enqueueToast(
                multipleIncidentsAiToastConfig(count),
              );
            }
            sessionStorage.setItem(NEW_INCIDENTS_TOAST_SEEN_KEY, "true"); // Set the flag to prevent the toast from showing again. Will be cleared on logout.
          }
        });
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      // run only on mount
    ],
  );

  return null;
};

export default AiToastIncidents;
