import type {Remotes} from "../types/runtimeConfig";

/**
 * Get best matching remote according to "pathPrefixes" matching. When there is more
 * "pathPrefixes" matching the one with more path fragments wins.
 *
 * @example When we have "remotes"
 *   {
 *      "incidents": {
 *        "entry": "/remoteEntry.js",
 *        "pathPrefixes": ["/incidents", "/investigate", "/administration/playbooks"]
 *      },
 *      "administration": {
 *        "entry": "/_next/static/chunks/remoteEntry.js",
 *        "pathPrefixes": ["/administration"]
 *      },
 *   }
 *
 *   "nextPath"="/administration/whatever" gives us "administration" remotes subset
 *   "nextPath"="/administration/playbooks/whatever" gives us "incidents" remotes subset
 *
 * @param nextPath path to be matched against "remotes"
 * @param remotes remote systems definition from config
 * @returns matching remote subset object
 */
export default function getUrlPathBestMatchingRemote(
  nextPath: string | undefined,
  remotes: Remotes,
) {
  const path = sanitizeRemotePath(nextPath);

  return (
    Object.entries(remotes)
      // flat pathPrefixes to individual array items containing necessary fields from original object
      .flatMap(([key, remote]) => {
        const pathPrefixes = remote.pathPrefixes ?? [];
        return pathPrefixes.map((pathPrefix) => ({
          key,
          pathPrefix,
          prefixValue: pathPrefix.split("/").length - 1,
          host: remote.host,
        }));
      })
      // keep in array only items with pathPrefixes matching nextPath
      .filter(({pathPrefix}) => path.startsWith(pathPrefix))
      // sort filtered prefixes according to number of matching path fragments
      .sort((a, b) => b.prefixValue - a.prefixValue)
      // take first (highest) or return undefined in case of empty array
      .shift()
  );
}

function sanitizeRemotePath(nextPath: string | undefined): string {
  if (typeof nextPath === "undefined" || nextPath === "/") {
    if (typeof process.env.DEFAULT_DESTINATION === "string") {
      return process.env.DEFAULT_DESTINATION;
    } else {
      throw new Error(
        "Missing required environment variable: DEFAULT_DESTINATION.",
      );
    }
  }

  return nextPath;
}
