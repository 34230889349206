import {useEffect} from "react";

import {logger} from "utils/logger";

export default function useServiceWorker() {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register(new URL("worker/sw", import.meta.url), {scope: "/"})
        .then(
          function (registration) {
            logger.info("Service Worker registration successful with scope: ", {
              scope: registration.scope,
            });
          },
          function (err) {
            logger.error("Service Worker registration failed: ", err);
          },
        );
    } else {
      // This is expected in development mode.
      // In chrome, add http://dev.9dcdd4915aad0ae7d12b8618:4000
      // to chrome://flags/#unsafely-treat-insecure-origin-as-secure
      // to enable service worker in development mode.
      logger.info("Service Worker registration skipped.");
    }
  }, []);
}
