// eslint-disable-next-line no-restricted-imports
import axios from "axios";

const tdrClient = axios.create({
  timeout: 60_000,
  baseURL: "/",
  paramsSerializer: {
    // Serializes arrays in params in Conure's preferred format:
    // {params: {foo: [1, 2]}} => `?foo=1&foo=2` (not `?foo[]=1&foo[]=2`)
    indexes: null,
  },
});

export default tdrClient;
