// based on https://github.com/watson/is-secret/blob/563cce48b4b89fd3f99221432a3f37b4662672b0/index.js#L3

const FORBIDDEN_KEYS = [
  // generic
  /passw(or)?d/i,
  /^pw$/,
  /^pass$/i,
  /secret/i,
  /token/i,
  /api[-._]?key/i,
  /session[-._]?id/i,

  // specific
  /^connect\.sid$/, // https://github.com/expressjs/session
] as const;

export const REDACTED_KEY = "<REDACTED_KEY>";

export function isForbiddenKey(key: string): boolean {
  return FORBIDDEN_KEYS.some(function (regex) {
    return regex.test(key);
  });
}
