import {CDSButton} from "@ciscodesignsystems/cds-react-button";

import styles from "./AiToast.module.scss";

interface AiToastDescriptionProps {
  description: string;
  buttonText?: string;
  dataEvent?: string;
  onButtonClick?: () => void;
}

const AiToastDescription = ({
  description,
  buttonText,
  dataEvent,
  onButtonClick,
}: AiToastDescriptionProps) => {
  return (
    <>
      <div className={styles.aiToastDescription}>{description}</div>
      {buttonText ? (
        <div className={styles.aiToastButtonContainer}>
          <CDSButton
            data-event={dataEvent}
            variant="secondary"
            onClick={onButtonClick}
            className={styles.aiToastButton}
          >
            {buttonText}
          </CDSButton>
        </div>
      ) : null}
    </>
  );
};

export default AiToastDescription;
