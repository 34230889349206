export const INT_DEPLOY = "xdr.int.iroh.site";
export const INT_DEPLOY_OLD = "securex.int.iroh.site";
export const INT_PREVIEW = "securex-ui-shell-int";
export const INT_PREVIEW_ALT = "securex-ui-shell"; // Delete once we figure out why preview URLs don't have int in it
export const TEST_DEPLOY = "xdr.test.iroh.site";
export const TEST_DEPLOY_OLD = "securex.test.iroh.site";
export const TEST_PREVIEW = "securex-ui-shell-test";
export const US_PROD_DEPLOY = "xdr.us.security.cisco.com";
export const US_PROD_DEPLOY_OLD = "securex.us.security.cisco.com";
export const US_PROD_PREVIEW = "securex-ui-shell-us";
export const EU_PROD_DEPLOY = "xdr.eu.security.cisco.com";
export const EU_PROD_DEPLOY_OLD = "securex.eu.security.cisco.com";
export const EU_PROD_PREVIEW = "securex-ui-shell-eu";
export const APJC_PROD_DEPLOY = "xdr.apjc.security.cisco.com";
export const APJC_PROD_DEPLOY_OLD = "securex.apjc.security.cisco.com";
export const APJC_PROD_PREVIEW = "securex-ui-shell-apjc";
export const DEV_HASH = process.env.DEV_HASH;

export const applicationNames = new Map([
  ["sc", "Secure Client"],
  ["xdr", "XDR"],
  ["sx", "SecureX"],
]);

export const newHostnames = [
  INT_DEPLOY,
  INT_PREVIEW,
  INT_PREVIEW_ALT,
  TEST_DEPLOY,
  TEST_PREVIEW,
  US_PROD_DEPLOY,
  US_PROD_PREVIEW,
  EU_PROD_DEPLOY,
  EU_PROD_PREVIEW,
  APJC_PROD_DEPLOY,
  APJC_PROD_PREVIEW,
  DEV_HASH,
];

export const previewHostnames = [
  INT_PREVIEW,
  INT_PREVIEW_ALT,
  TEST_PREVIEW,
  US_PROD_PREVIEW,
  EU_PROD_PREVIEW,
  APJC_PROD_PREVIEW,
];
