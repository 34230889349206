import {useRouter} from "next/router";
import {useEffect, useState} from "react";

import {navigationController} from "utils/navigationController";
import getUrlPathBestMatchingRemote from "utils/getUrlBestMatchingRemote";

import useConfig from "./useConfig";

/**
 * This hook watches for changes to the <head> element and adds external
 * stylesheets to a lookup dict. It also watches for route changes and
 * enables/disables the stylesheets for the current app as needed. The UI
 * is hidden when the route changes to prevent a flash of unstyled content.
 * This can be increased if needed.
 * @param {number} hideDelay Time in milliseconds where we hide the UI.
 * @returns {{isChangingApps: boolean}}
 */
const useActiveAppStylesheets = (hideDelay = 450) => {
  const {config} = useConfig();
  const {remotes} = config;
  const {asPath, events} = useRouter();
  const [currentRouteHost, setCurrentRouteHost] = useState(undefined);
  const [isChangingApps, setIsChangingApps] = useState(false);

  useEffect(() => {
    if (!remotes) return;

    const remote = getUrlPathBestMatchingRemote(location.pathname, remotes);
    if (!remote) return;

    // Set on page load to prevent flash on first sub-route change.
    setCurrentRouteHost(remote.host);
  }, [remotes]);

  useEffect(() => {
    /**
     * @param {string | undefined} routeHost
     */
    function enableStyleSheets(routeHost) {
      const extStyleSheets = document.querySelectorAll(
        "head link[rel=stylesheet][href^=http]",
      );
      if (routeHost) {
        extStyleSheets.forEach((sheet) => {
          const shouldDisable = !sheet.href.startsWith(routeHost);
          if (sheet.disabled !== shouldDisable) {
            sheet.disabled = shouldDisable;
          }
        });
      }
    }

    function handleRouteChangeStart(nextRoutePath) {
      if (navigationController.isNavigationBlocked(nextRoutePath)) {
        return;
      }
      const remote = getUrlPathBestMatchingRemote(nextRoutePath, remotes);
      const nextRouteHost = remote?.host;

      if (currentRouteHost !== nextRouteHost) {
        enableStyleSheets(nextRouteHost);
        setIsChangingApps(true);
        setTimeout(() => {
          setIsChangingApps(false);
        }, hideDelay);
      }
      setCurrentRouteHost(nextRouteHost);
    }

    events.on("routeChangeStart", handleRouteChangeStart);

    return () => {
      events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, [asPath, currentRouteHost, events, hideDelay, remotes]);

  return {isChangingApps};
};

export default useActiveAppStylesheets;
