import dynamic from "next/dynamic";

import handleImportError from "utils/handleImportError";

const RemoteRibbon = dynamic(
  // eslint-disable-next-line import/no-unresolved
  () => import("ribbon/app").catch(handleImportError),
  {
    ssr: false,
  },
);

export default RemoteRibbon;
