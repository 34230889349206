/**
 * Performs a deep merge on several objects, concatenating arrays as it goes.
 * Equivalent to _.mergeWith(foo, bar, (a, b) => Array.isArray(a) ? a.concat(b) : undefined)
 * @param {...Object} args Objects to merge
 * @returns {Object} Merged object
 * @example mergeDeep(foo, bar, baz)
 * @example mergeDeep({ a: { b: [1, 2] } }, { a: { b: [3, 4] } })
 */
function mergeDeep(...args) {
  return args.reduce((acc, obj) => {
    if (obj === null) {
      return null;
    }
    Object.keys(obj).forEach((key) => {
      if (Array.isArray(acc[key]) && Array.isArray(obj[key])) {
        acc[key] = zipperMerge(acc[key], obj[key]);
      } else if (typeof acc[key] === "object" && typeof obj[key] === "object") {
        acc[key] = mergeDeep(acc[key], obj[key]);
      } else if (obj[key] === null) {
        acc[key] = null;
      } else if (obj[key] === false) {
        acc[key] = false;
      } else if (obj[key]) {
        acc[key] = obj[key];
      }
    });
    return acc;
  }, {});
}

/**
 * Merges two arrays so that the resulting array contains all elements from
 * both arrays, alternating between them.
 * @param {Array} arr1 The first array
 * @param {Array} arr2 The second array
 * @returns
 */
function zipperMerge(arr1, arr2) {
  const result = [];
  const maxLength = Math.max(arr1.length, arr2.length);

  for (let i = 0; i < maxLength; i++) {
    if (i < arr1.length) result.push(arr1[i]);
    if (i < arr2.length) result.push(arr2[i]);
  }

  return result;
}

module.exports = mergeDeep;
