import {logger} from "utils/logger";

export const XDR_FEATURE_FLAG = "xdr-feature-flags";

// In the future we should consider adding more robust feature flag support ex: https://github.com/advthreat/securex-ui-monorepo/blob/main/packages/commons/src/utils/index.js#L52

export const getAllFeatureFlags = () =>
  JSON.parse(localStorage.getItem(XDR_FEATURE_FLAG) ?? "{}");

export const getFeatureFlag = (flag: string): string | undefined => {
  if (typeof flag !== "string") {
    logger.error("getFeatureFlag requires a string as an argument");
    return undefined;
  } else {
    const flags = getAllFeatureFlags();
    return flags?.[flag];
  }
};

export const setFeatureFlag = (flag: string, value: boolean) => {
  if (typeof flag !== "string" || typeof value !== "boolean") {
    logger.error(
      "setFeatureFlag takes a string and boolean value as arguments",
    );
  } else {
    try {
      const currentFlags = getAllFeatureFlags();
      const updatedFlags = {...currentFlags, [flag]: value};
      localStorage.setItem(XDR_FEATURE_FLAG, JSON.stringify(updatedFlags));
      logger.info(`The flag ${flag} has been successfully set to ${value}`);
    } catch (error) {
      logger.error(
        `An error occured trying to set ${XDR_FEATURE_FLAG} in localStorage. Error: ${error} `,
      );
    }
  }
};
