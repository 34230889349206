import {Trans} from "react-i18next";

import {CDSButton} from "@ciscodesignsystems/cds-react-button";
import {CDSPopover} from "@ciscodesignsystems/cds-react-popover";

import styles from "./AiOnboardingPopover.module.scss";

interface AiOnboardingPopoverProps {
  children: React.ReactElement;
  onCancelClick: () => void;
  onContinueClick: () => void;
  open?: boolean;
}

export default function AiOnboardingPopover({
  children,
  onCancelClick,
  onContinueClick,
  open,
}: AiOnboardingPopoverProps) {
  const popoverContent = (
    <div>
      <p className={styles.popoverContentTitle}>
        <Trans i18nKey="ai.onboarding.introduction" />
      </p>
      <p>
        <Trans i18nKey="ai.onboarding.quickTour" />
      </p>
      <CDSButton.Group className={styles.buttonGroup}>
        <CDSButton size="sm" variant="tertiary" onClick={onCancelClick}>
          <Trans i18nKey="ai.onboarding.cancel" />
        </CDSButton>
        <CDSButton size="sm" variant="primary" onClick={onContinueClick}>
          <Trans i18nKey="ai.onboarding.letsGo" />
        </CDSButton>
      </CDSButton.Group>
    </div>
  );
  return (
    <CDSPopover
      open={open}
      title={popoverContent}
      placement="bottom"
      size="lg"
      className={styles.popover}
    >
      {children}
    </CDSPopover>
  );
}
