import {useRouter} from "next/router";
import {Question} from "phosphor-react";

import {CDSButton} from "@ciscodesignsystems/cds-react-button";

import useAuth from "hooks/useAuth";
import useConfig from "hooks/useConfig";
import {publish} from "eventBus/publish";

import styles from "./HelpMenu.module.scss";

export default function HelpMenu() {
  const {isSecureClient} = useAuth();
  const {config} = useConfig();
  const {apis, docs} = config;
  const {asPath} = useRouter();

  function handleClick() {
    const docsHref = findDocsLink(docs, asPath);
    const docsWindow = window.open(
      isSecureClient
        ? apis.scDocs.base + "/Content/Secure-Client/client-management.htm"
        : apis.docs.base + docsHref,
    );
    publish({
      topic: "helpClicked",
      payload: {
        href: docsHref,
        window: docsWindow,
      },
    });
  }

  return (
    <CDSButton
      aria-label="Help content"
      data-testid="header-help-button"
      icon={<Question weight="regular" size={24} />}
      onClick={handleClick}
      sentiment="inverse"
      variant="ghost"
      className={styles.button}
    />
  );
}

function findDocsLink(docs, path) {
  const matchingDocsKey = Object.keys(docs).find((key) => {
    // replace all RegExp metacharacters except *
    const escapedPath = key.replace(/[.+?^${}()|[\]\\]/g, "\\$&");
    // Turn * in to a RegExp wildcard
    const routeMatchRegExp = new RegExp(
      `^${escapedPath.replaceAll(/\*/g, ".+")}`,
    );

    return routeMatchRegExp.test(path);
  });

  return docs[matchingDocsKey] || "/";
}
