import irohClient from "apis/iroh/irohClient";

/**
 * Fetches the IROH system status
 * {@link https://visibility.int.iroh.site/iroh/status/index.html#/default/get_iroh_status_status}
 * @typedef {"healthy" | "degraded"} Status
 * @typedef {"high" | "medium" | "low" | undefined} Severity
 * @returns {Promise<{ status: Status, severity: Severity }>} System status & severity
 */
export async function getSystemStatus() {
  return irohClient.get("/status/status").then(({data}) => {
    const {severity, status} = data;
    return {
      severity,
      status: status === "ok" ? "healthy" : "degraded",
    };
  });
}
