import {createContext, useEffect, useState} from "react";

import {datadogRum} from "@datadog/browser-rum";
import {datadogLogs} from "@datadog/browser-logs";

import RoleChangedModal from "components/modals/RoleChangedModal";
import Spinner from "components/Spinner";
import useBroadcastChannel from "hooks/useBroadcastChannel";
import {
  goToLogin,
  goToLogout,
  getLocalAuthToken,
  getCodeParam,
  getProfileInfo,
  postAuthCode,
  cleanupCodeParamAndRedirect,
  getReadOnlyRefreshSessionToken,
  getReadOnlyAuthSessionToken,
} from "utils/auth";
import mergeDeep from "utils/mergeDeep";
import {setCommonMetadata as setTelemetryMetadata} from "utils/telemetryQueue";

import {hasImpersonationsDB} from "../utils/impersonationDB";

export interface User {
  lastLoggedAt?: string[];
  role?: string;
  scopes?: string[];
  userEmail?: string;
  userId?: string;
  userName?: string;
  userNick?: string;
}

export interface Org {
  id?: string;
  enterpriseId?: string;
  name?: string;
  additionalScopes?: string[];
  mainApp?: string;
}

export interface Account {
  org?: Org;
  user?: User;
  stats?: {
    allowAllRoleToLogin?: boolean;
  };
}

interface Profile {
  accounts: Account[];
  idp: Record<string, unknown> | null;
  isSecureClient: boolean | null;
  org: Org;
  user: User;
}

interface AuthContext extends Profile {
  goToLogin: () => void;
  goToLogout: () => void;
  isLoading: boolean | null;
}

export const AuthContext = createContext<AuthContext>({
  accounts: [],
  goToLogin: () => {},
  goToLogout: () => {},
  idp: null,
  isLoading: null,
  isSecureClient: null,
  org: {},
  user: {},
});

export default function AuthProvider({children}: {children: React.ReactNode}) {
  const [code, setCode] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [roleChangeError, setRoleChangeError] = useState("");
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [{idp, org, user, accounts, isSecureClient}, setProfile] =
    useState<Profile>({
      idp: {},
      org: {},
      user: {},
      accounts: [],
      isSecureClient: null,
    });

  // This callback function will be called when the worker sends a message to the broadcast channel
  // In this the logic will be executed when the user's session has expired
  useBroadcastChannel<{error: string; error_description: string}>(
    ({type, payload}) => {
      // We need to check if the user's session expired or they're using read-only
      // tokens for full screen SOC mode as we now allow a 30 day login read-only session.
      if (
        type === "AUTH_EXPIRED" &&
        !getReadOnlyAuthSessionToken() &&
        !getReadOnlyRefreshSessionToken()
      ) {
        if (payload.error === "user_role_changed") {
          setRoleChangeError(payload.error_description);
        } else {
          goToLogin();
        }
      }
    },
  );

  useEffect(() => {
    const authToken = getLocalAuthToken();
    const code = getCodeParam();

    if (code) {
      // The user is logging in.
      setCode(code);
    } else if (authToken) {
      // The user is already logged in.
      setAuthToken(authToken);
    } else if (window.location.pathname == "/impersonations") {
      hasImpersonationsDB().then(() => setLoading(false));
    } else {
      // The user needs to log in.
      goToLogin();
    }
  }, []);

  useEffect(() => {
    if (code && !authToken) {
      postAuthCode(code)
        .then(({data: {accessToken}}) => {
          setCode(null);
          setAuthToken(accessToken);
        })
        .catch(goToLogin)
        .finally(cleanupCodeParamAndRedirect);
    }
  }, [code, authToken]);

  useEffect(() => {
    if (authToken) {
      getProfileInfo()
        .then(({data}) => {
          // Set profile info
          const preciseScopes = getPreciseScopes(data.user.scopes);
          const profile = mergeDeep(data, {
            user: {scopes: preciseScopes},
          }) as Profile;
          setProfile(profile);
          setTelemetryMetadata({
            app: data.application,
          });

          // Set the user context for Datadog RUM

          // enable PII collection in development, INT, and TEST for debugging purposes
          const piiCollectionEnabled =
            process.env.NODE_ENV === "development" ||
            process.env.REGION?.startsWith("INT") ||
            process.env.REGION === "TEST";

          const user = {
            email: piiCollectionEnabled ? profile.user.userEmail : undefined,
            id: profile.user.userId,
            name: piiCollectionEnabled ? profile.user.userName : undefined,
            org: {
              id: profile.org.id,
              name: piiCollectionEnabled && profile.org.name, // not PII but might be sensitive
              xdr: !!profile.org.enterpriseId,
            },
            role: profile.user.role,
          };

          datadogRum.setUser(user);
          datadogLogs.setUser(user);

          // Finish loading
          setLoading(false);
        })
        .catch(goToLogin);
    }
  }, [authToken]);

  return (
    <AuthContext.Provider
      value={{
        accounts,
        goToLogin,
        goToLogout,
        idp,
        isLoading: loading,
        isSecureClient,
        org,
        user,
      }}
    >
      {loading ? <Spinner /> : children}
      <RoleChangedModal error={roleChangeError} />
    </AuthContext.Provider>
  );
}

/**
 * Get read/write actions to scopes that only have a resource value
 * @param scopes Array of current user scopes
 * @returns Array of scopes with actions
 * @example ["alerts"] => ["alerts:read", "alerts:write"]
 */
function getPreciseScopes(scopes: string[]) {
  return scopes.reduce<string[]>((acc, scope) => {
    const [resource, action] = scope.split(":");
    if (!action) {
      acc.push(`${resource}:read`);
      acc.push(`${resource}:write`);
    }
    return acc;
  }, []);
}
