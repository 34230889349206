import {jwtDecode} from "jwt-decode";

import {getLocalAuthToken, getLocalRefreshToken} from "utils/auth";

import {replaceAllPatterns, type PatternReplacement} from "../util/replacement";

function canDecodeJwt(jwtCandidate: string): boolean {
  try {
    jwtDecode(jwtCandidate, {header: true});
    return true;
  } catch {
    return false;
  }
}

function getJwtMatches(value: string): string[] {
  // regex based on https://stackoverflow.com/a/74325712 without requiring the whole string to be a JWT
  const jwtCandidateRegex = /[A-Za-z0-9_-]{2,}(?:\.[A-Za-z0-9_-]{2,}){2}/g;
  const jwtCandidates = value.match(jwtCandidateRegex) ?? [];
  // filter out invalid matches (eg. some.hostname.com)
  return jwtCandidates.reduce<string[]>((acc, candidate) => {
    if (canDecodeJwt(candidate)) {
      acc.push(candidate);
    }
    return acc;
  }, []);
}

/**
 * Redacts JWTs in a string by replacing it with a placeholder value.
 * @param arg - The argument to be scrubbed. Not string values are returned as is.
 */
export function redactJWT(arg: unknown) {
  if (typeof arg !== "string") {
    return arg;
  }
  // redact string
  const REDACTED_JWT = "<REDACTED_JWT>";

  const patterns: PatternReplacement[] = [];

  // find all JWTs in the value
  const jwtMatches = getJwtMatches(arg);

  // redact any JWT matches
  jwtMatches.forEach((jwtMatch) => {
    patterns.push({pattern: jwtMatch, replacement: REDACTED_JWT});
  });

  // if local storage is available (not available on server)
  if (typeof window !== "undefined") {
    // redact current auth token if it exists
    const currentAuthToken = getLocalAuthToken();
    if (currentAuthToken) {
      patterns.push({pattern: currentAuthToken, replacement: REDACTED_JWT});
    }

    // redact current refresh token if it exists
    const currentRefreshToken = getLocalRefreshToken();
    if (currentRefreshToken) {
      patterns.push({
        pattern: currentRefreshToken,
        replacement: REDACTED_JWT,
      });
    }
  }

  const redactedValue = replaceAllPatterns(arg, patterns);

  return redactedValue;
}
