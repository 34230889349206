import {
  type AggregatedSubEvent,
  type AggregatedSubTopic,
  subscribe,
} from "./aggregatedChannel";
import {useEventCallback} from "./useEventCallback";

/**
 * A hook to subscribe to all event busses
 * @param topic The topic to subscribe to
 */
export function useAggregatedEventCallback<T extends AggregatedSubTopic>(
  topic: T,
  callback: (event: Extract<AggregatedSubEvent, {topic: T}>) => void,
) {
  useEventCallback(subscribe, topic, callback);
}
