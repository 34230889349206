/* eslint-disable no-restricted-syntax */
import type {Logger as DatadogLogger} from "@datadog/browser-logs";
import {datadogLogs} from "@datadog/browser-logs";

import {getDatadogInitConfiguration} from "utils/datadog";

import {getRedacted} from "../redact";

import type {Logger} from "..";

type LogMethodWithMeta = (
  message: string,
  messageContext?: {meta?: unknown},
  error?: Error,
) => void;

export class ClientLogger implements Logger {
  private datadogLogger?: DatadogLogger;

  constructor() {
    const {isEnabled} = getDatadogInitConfiguration();
    if (isEnabled) {
      this.datadogLogger = datadogLogs.createLogger("ClientLogger", {
        level: "debug",
        handler: "http",
      });
    }
  }

  private callLogMethod(
    datadogLogMethod: LogMethodWithMeta | undefined,
    consoleLogMethod: (message: string, meta?: unknown) => void,
    message: string,
    meta?: unknown,
  ) {
    const redactedMessage = getRedacted(message); // redact message
    const redactedMeta =
      typeof meta !== "undefined" ? getRedacted(meta) : undefined; // redact meta
    if (datadogLogMethod) {
      datadogLogMethod.call(
        this.datadogLogger,
        redactedMessage,
        {
          ...datadogLogs.getGlobalContext(),
          ...(redactedMeta ? {meta: redactedMeta} : {}),
        },
        redactedMeta instanceof Error ? redactedMeta : undefined,
      );
    }
    if (redactedMeta) {
      consoleLogMethod(redactedMessage, redactedMeta);
    } else {
      consoleLogMethod(redactedMessage);
    }
  }

  public error(message: string, meta?: unknown) {
    this.callLogMethod(this.datadogLogger?.error, console.error, message, meta);
  }

  public warn(message: string, meta?: unknown) {
    this.callLogMethod(this.datadogLogger?.warn, console.warn, message, meta);
  }

  public info(message: string, meta?: unknown) {
    this.callLogMethod(this.datadogLogger?.info, console.info, message, meta);
  }

  public debug(message: string, meta?: unknown) {
    this.callLogMethod(this.datadogLogger?.debug, console.debug, message, meta);
  }
}
