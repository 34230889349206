// eslint-disable-next-line no-restricted-imports
import axios from "axios";

import {getConfig} from "utils/config";
import {axiosRequestAuthorizationHeaderInterceptor} from "utils/axios";

const conureClient = axios.create({
  timeout: 60_000,
  paramsSerializer: {
    // Serializes arrays in params in Conure's preferred format:
    // {params: {foo: [1, 2]}} => `?foo=1&foo=2` (not `?foo[]=1&foo[]=2`)
    indexes: null,
  },
});

/**
 * Setup interceptors
 */

// Set baseURL based on runtime config
conureClient.interceptors.request.use(async (axiosConfig) => {
  const runtimeConfig = await getConfig();
  axiosConfig.baseURL = runtimeConfig.apis.conure.base;
  return axiosConfig;
});

// Add JWT token
conureClient.interceptors.request.use(
  axiosRequestAuthorizationHeaderInterceptor,
);

export default conureClient;
