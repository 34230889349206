import {useEffect, useState} from "react";

import {LS_KEY} from "types/bannerState";

import type {BannerStates} from "types/bannerState";

function usePersistedBannerStates() {
  const [storedBanners, setStoredBanners] = useState((): BannerStates[] => {
    const bannersFromLocalstorage = JSON.parse(
      localStorage.getItem(LS_KEY) ?? "[]",
    ) as BannerStates[];
    return bannersFromLocalstorage ? bannersFromLocalstorage : [];
  });

  const setBanners = (newBanners: BannerStates[]) => {
    setStoredBanners(newBanners);
    window.localStorage.setItem(LS_KEY, JSON.stringify(newBanners));
    window.dispatchEvent(new Event("storage")); // Trigger storage event so that all tabs update
  };

  useEffect(() => {
    const onStorageUpdate = (e: StorageEvent) => {
      const {key, newValue} = e;

      if (key === LS_KEY && newValue) {
        setStoredBanners(JSON.parse(newValue));
      }
    };

    window.addEventListener("storage", onStorageUpdate);

    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  return [storedBanners, setBanners] as const;
}

export default usePersistedBannerStates;
