import conureClient from "apis/conure/conureClient";
import {logger} from "utils/logger";

import type {IncidentSearchParams} from "components/AiAssistant/toast/utils";

/**
 * Get incidents by search based on provided params (using Conure v2 endpoint).
 * @param params Incident search params.
 * @param limit Number of returned incidents.
 * @returns Array of incidents.
 */
export async function getIncidentsBySearch(
  params: IncidentSearchParams,
  limit = 10,
) {
  try {
    const response = await conureClient.get("/v2/incident/search", {
      params: {...params, limit: limit},
    });
    return response.data;
  } catch (error) {
    logger.error("There was a problem fetching incidents:", error);
    throw error;
  }
}
