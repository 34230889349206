import type {RuntimeConfig} from "types/runtimeConfig";

/**
 * Singleton promise that resolves to the app runtime config.
 * It is set to the promise when the first call to getConfig is made.
 */
let configPromise: Promise<RuntimeConfig> | undefined;

/**
 * The function returns a singleton promise that resolves to the app config.
 * The singleton is here to allow calling the function from multiple places with only single request being made.
 */
export async function getConfig() {
  if (!configPromise) {
    // crate a promise that will be resolved when the config
    // is saved to window.CONFIG (just waiting for fetch to finish is not enough)
    configPromise = new Promise((resolve, reject) => {
      fetch(`${location.origin}/api/config/runtime`)
        .then((res) => res.json())
        .then((config: RuntimeConfig) => {
          Object.assign(window.__SHELL__, config);
          // resolve the promise after the config is saved to window
          resolve(config);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  return configPromise;
}

/**
 * Clears the config promise singleton so that `getConfig` fetches the config again.
 */
export function clearConfig() {
  // clear configPromise promise, so that it loads again when app is mounting again
  configPromise = undefined;
}
