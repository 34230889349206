import Image from "next/image";

import {CDSButton} from "@ciscodesignsystems/cds-react-button";

import styles from "./Error.module.scss";

export default function Error({title, buttonText, onButtonClick, children}) {
  return (
    <div className={styles.container}>
      <Image
        className={styles.image}
        src="/images/page-danger.svg"
        alt="Error"
        width="298"
        height="238"
      />
      <h3 className={styles.header}>{title}</h3>
      {children ? <div className={styles.message}>{children}</div> : null}
      {buttonText ? (
        <CDSButton onClick={onButtonClick} className={styles.button}>
          {buttonText}
        </CDSButton>
      ) : null}
    </div>
  );
}
