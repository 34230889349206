import {useMemo} from "react";

import useAuth from "./useAuth";
import useConfig from "./useConfig";
import useFrozenWindowVariable from "./useFrozenWindowVariable";

const WINDOW_AI_PERMITTED_KEY = "__XDR_AI_ASSISTANT_PERMITTED__";
const SECURITY_ANALYST_ROLE = "sat";
const BETA_SCOPE = "cisco/feature-flag/xdr-ai-assistant";

export default function useIsAiAssistantEnabled() {
  const {user, org, isLoading: isAuthLoading} = useAuth();
  const {config, isLoading: isConfigLoading} = useConfig();
  const {apis, region} = config;

  const isLoading = useMemo(
    () => isAuthLoading || isConfigLoading,
    [isAuthLoading, isConfigLoading],
  );

  const isEnabled = useMemo(() => {
    const isEnvironmentConfigured = !!apis?.ai;
    const isUserAuthorized = user?.role !== SECURITY_ANALYST_ROLE;
    const isBetaCustomer = org?.additionalScopes?.includes(BETA_SCOPE) ?? false;

    if (isEnvironmentConfigured && isUserAuthorized) {
      return region.startsWith("INT") ? true : isBetaCustomer;
    } else {
      return false;
    }
  }, [apis?.ai, org, region, user?.role]);

  // Share to other MFEs
  useFrozenWindowVariable(WINDOW_AI_PERMITTED_KEY, isEnabled, !isLoading);

  return isLoading ? null : isEnabled;
}
