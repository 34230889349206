import type {Subscription, XDRSubEvent} from "@xdr-ui/event-client";
import {
  type Event as AiAssistantEvent,
  subscribe as subscribeAiAssistant,
} from "@xdr-ui/event-client-ai-assistant";
import {
  type Event as IncidentsEvent,
  subscribe as subscribeIncidentsEvent,
} from "@xdr-ui/event-client-incidents";

export type AggregatedSubEvent = XDRSubEvent<AiAssistantEvent | IncidentsEvent>;
export type AggregatedSubTopic = AggregatedSubEvent["topic"];

export function subscribe(
  subscriber: (event: AggregatedSubEvent) => void,
  handler?: (event: AggregatedSubEvent) => boolean,
): Subscription {
  const subscribers = [subscribeAiAssistant, subscribeIncidentsEvent].map(
    (subscribe) => subscribe(subscriber, handler),
  );
  return {
    get closed() {
      return subscribers.every((subscriber) => subscriber?.closed);
    },
    unsubscribe: () => {
      subscribers.forEach((subscriber) => subscriber?.unsubscribe());
    },
  };
}
