import {useRef} from "react";

import {logger} from "utils/logger";

const gridAreaNames = ["header", "sidebar", "content"];

const commaSeparatedNames =
  gridAreaNames.slice(0, gridAreaNames.length - 1).join(", ") +
  `, and ${gridAreaNames[gridAreaNames.length - 1]}`;

const useGridAreaPositions = () => {
  const gridAreas = useRef(
    gridAreaNames.reduce((refs, id) => {
      refs[id] = {
        ref: null,
        position: new DOMRect(),
      };
      return refs;
    }, {}),
  );

  const registerGridArea = (id) => (ref) => {
    // The component is unmounting in DOM
    if (!ref) {
      return;
    }
    if (!gridAreaNames.includes(id)) {
      logger.warn(
        `You've passed an invalid grid area name: ${id}. Only ${commaSeparatedNames} are supported. If you'd like to use this id, add it in useGridAreaPositions.js`,
      );
      return;
    }
    const gridArea = gridAreas.current[id];
    gridArea.ref = ref;
    gridArea.position = ref.getBoundingClientRect();
    if (!window.__SHELL__.layout) {
      window.__SHELL__.layout = {};
    }
    window.__SHELL__.layout[id] = gridArea;
  };

  return {gridAreas, registerGridArea};
};

export default useGridAreaPositions;
