import {forwardRef, useMemo} from "react";
import Link from "next/link";

import {CDSNav} from "@ciscodesignsystems/cds-react-nav";

import useNav from "hooks/useNav";
import useAuth from "hooks/useAuth";

import NavMenu from "./NavMenu";
import {useTranslateTitle} from "./useTranslatedTitle";

import type {NavItem} from "context/NavProvider";

import styles from "./Navigation.module.scss";

export default forwardRef<HTMLElement>(function Navigation(_, ref) {
  const t = useTranslateTitle();
  const {navTree, isExpanded, setSidebarState, activeTopNav, isUnauthorized} =
    useNav();
  const {isSecureClient} = useAuth();
  const toggleExpandedSideBar = () =>
    setSidebarState((state) => ({
      ...state,
      isExpanded: !state.isExpanded,
    }));

  const navItemSections = useMemo(
    () =>
      Object.values(
        navTree.reduce<Record<number, NavItem[]>>((acc, item) => {
          const section = item.section || 0;
          acc[section] = acc[section] || [];
          acc[section].push(item);
          return acc;
        }, {}),
      ),
    [navTree],
  );

  const renderNavItems = (section: NavItem[]) =>
    section.map((navItem) => {
      const {id, href, subNavigation, icon, rbac, xdrOnly} = navItem;
      if (xdrOnly && isSecureClient) return null;
      if (rbac && isUnauthorized(rbac)) return null;

      return (
        // @ts-expect-error problem witch children property on CDSNav.Item
        <CDSNav.Item
          key={`top-nav-${id}`}
          icon={icon}
          isSelected={activeTopNav === href}
          {...(activeTopNav === href ? {["aria-current"]: "page"} : {})}
          href={href}
          as={Link}
          menu={subNavigation && <NavMenu subNavItems={subNavigation} />}
        >
          {t(navItem)}
        </CDSNav.Item>
      );
    });

  return (
    <nav id="nav" className={styles.wrapper} ref={ref}>
      <CDSNav
        iconOnlyCollapsed={true}
        isCollapsed={!isExpanded}
        setCollapsed={toggleExpandedSideBar}
        data-testid="main-nav"
      >
        {navItemSections.map((section, index) => (
          <CDSNav.Section key={`nav-section-${index}`}>
            {renderNavItems(section)}
          </CDSNav.Section>
        ))}
      </CDSNav>
    </nav>
  );
});
