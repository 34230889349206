/* eslint-disable no-restricted-syntax */
import stringify from "safe-stable-stringify";

import {getRedacted, redactObject} from "../redact";

import type {Logger as WinstonLogger} from "winston";
import type {LogMethod, Logger} from "..";
import type {TransformableInfo, Format} from "logform";

export class ServerLogger implements Logger {
  private winstonLogger?: WinstonLogger;

  constructor() {
    // only initialize the server logger if we are on the server
    if (typeof window === "undefined") {
      // only require winston if we are on the server to avoid fs import errors on the client
      // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/consistent-type-imports
      const winston = require("winston") as typeof import("winston");

      const loggerTransports = [
        new winston.transports.Console({handleExceptions: true}),
      ];
      const redactFormat = winston.format(
        (info: TransformableInfo): TransformableInfo => {
          redactObject(info);
          return info;
        },
      );
      const devFormats: Format[] = [
        winston.format.timestamp(),
        winston.format.errors({stack: true}),
        winston.format.printf(
          ({timestamp, level, message, stack, meta}): string => {
            return [
              timestamp,
              level,
              message,
              stringify(meta),
              ...(stack ? [stack] : []),
            ].join(" - ");
          },
        ),
      ];

      const prodFormats: Format[] = [
        winston.format.timestamp(),
        redactFormat(),
        winston.format.json(),
      ];

      const formats: Format[] =
        process.env.NODE_ENV === "development" ? devFormats : prodFormats;

      this.winstonLogger = winston.createLogger({
        level: "info",
        exitOnError: false,
        defaultMeta: {},
        format: winston.format.combine(...formats),
        transports: loggerTransports,
        exceptionHandlers: loggerTransports,
      });
    }
  }

  private callLogMethod(
    logMethod: LogMethod | undefined,
    message: string,
    meta?: unknown,
  ) {
    if (!this.winstonLogger || !logMethod) {
      console.error(
        "Server logger not initialized. This code should only run on the server.",
      );
      return;
    }
    logMethod.call(this.winstonLogger, getRedacted(message), {
      meta: getRedacted(meta),
    });
  }

  /**
   * Wrap the exported winston logger interface.
   *
   * This is done to prevent the logger from being used in a way that would
   * potentially leak sensitive information. Winston's logger interface
   * is very flexible and the types are not restrictive enough to prevent misuse.
   *
   * Eg. passing an object as the second argument to winstonLogger.error causes
   * the object to the spread into the log message root, making it hard
   * to redact reliably.
   *
   * Wrapper methods preemptively redact the arguments, then there is another
   * round of redaction in the format function just to be sure nothing was missed.
   *
   */

  public error(message: string, meta?: unknown): void {
    this.callLogMethod(this.winstonLogger?.error, message, meta);
  }

  public warn(message: string, meta?: unknown): void {
    this.callLogMethod(this.winstonLogger?.warn, message, meta);
  }

  public info(message: string, meta?: unknown): void {
    this.callLogMethod(this.winstonLogger?.info, message, meta);
  }

  public debug(message: string, meta?: unknown): void {
    this.callLogMethod(this.winstonLogger?.debug, message, meta);
  }
}
