import {XDREventClient} from "@xdr-ui/event-client";

import type {ShellEvent} from "./types";

export const CHANNEL_NAME = "shell";
export type ChannelName = typeof CHANNEL_NAME;

const channel = new XDREventClient<ChannelName, ShellEvent>(CHANNEL_NAME);

export function getChannel() {
  return channel;
}
