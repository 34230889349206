import traverse from "traverse";

import {redactAxiosError} from "./sanitizers/axios";
import {redactJWT} from "./sanitizers/jwt";
import {REDACTED_KEY, isForbiddenKey} from "./util/forbiddenKeys";

function getRedactedValue(value: unknown) {
  return redactJWT(redactAxiosError(value));
}

function isValidForbiddenKey(key: string | undefined): boolean {
  return typeof key === "string" && isForbiddenKey(key);
}

/**
 * Redact an object (in place) by redacting sensitive information.
 * @param obj - Object to be sanitized.
 */
export function redactObject(obj: object) {
  // skip redaction in development
  if (process.env.NODE_ENV === "development") {
    return;
  }
  traverse(obj).forEach(function (value) {
    if (isValidForbiddenKey(this.key)) {
      // redact forbidden key
      this.update(REDACTED_KEY);
    } else {
      // redact value
      this.update(getRedactedValue(value));
    }
  });
}

/**
 * Get redacted argument value by redacting sensitive information.
 * @param obj - Argument to be sanitized.
 * @returns Redacted argument value.
 */
export function getRedacted(obj: unknown) {
  // skip redaction in development
  if (process.env.NODE_ENV === "development") {
    return obj;
  }
  return traverse(obj).map(function (value) {
    if (isValidForbiddenKey(this.key)) {
      // redact forbidden key
      this.update(REDACTED_KEY);
    } else {
      // redact value
      this.update(getRedactedValue(value));
    }
  });
}
