import {logger} from "utils/logger";

import {freezeDeep} from "./freezeDeep";

function shouldFreeze(value) {
  return typeof value === "object" && value !== null;
}

/**
 * WARNING: DO NOT put any sensitive data on `window`.
 *
 * A class for managing a variable on the `window` object that cannot be easily mutated without an access
 * token created from this class. This prevents a trivial browser console statement from quickly updating
 * a variable on the `window` .e.g. `window.someFeatureFlagVariable = true`
 *
 * Do note, however, that this DOES NOT entirely prevent the `window` variable from being mutated by a bad
 * actor. There are still ways to mutate the variable this class claims to "lock" through means of the
 * JavaScript debugger, which means you should NEVER put anything sensitive on the `window`, even with
 * this class.
 *
 * In summary, the purpose of this class IS NOT to entirely block any mutation on a global `window`
 * variable, but instead, make it more difficult to do so.
 *
 * @example
 * ```
 * const frozenWindowVariable = new FrozenWindowVariable("color", "blue");
 *
 * console.log(window.color); // "blue"
 *
 * window.color = "red";
 *
 * console.log(window.color); // "blue"
 *
 * delete window.color;
 *
 * console.log(window.color); // "blue"
 *
 * frozenWindowVariable.color = "yellow";
 *
 * console.log(window.color); // "yellow"
 * ```
 */
export class FrozenWindowVariable {
  /**
   *
   * @param {String} key name of property to set on `window`
   * @param {any} initialValue value of the property to set on `window`
   * @returns
   */
  static init(key, initialValue) {
    const accessToken = Symbol(key);

    let currentValue = shouldFreeze(initialValue)
      ? freezeDeep(initialValue)
      : initialValue;

    try {
      Object.defineProperty(window, key, {
        get() {
          return currentValue;
        },
        set(payload) {
          if (payload && payload.accessToken === accessToken) {
            const nextValue = payload.value;

            currentValue = shouldFreeze(nextValue)
              ? freezeDeep(nextValue)
              : nextValue;
          }
        },
      });
    } catch (e) {
      logger.warn("FrozenWindowVariable failed to init", e);
    }

    return accessToken;
  }

  /**
   *
   * @param {String} key name of property to set on `window`
   * @param {any} initialValue value of the property to set on `window`
   * @returns
   */
  constructor(key, initialValue) {
    this.key = key;
    this.accessToken = FrozenWindowVariable.init(key, initialValue);
  }

  get value() {
    return window[this.key];
  }

  set value(value) {
    window[this.key] = {
      accessToken: this.accessToken,
      value,
    };
  }
}
