import router from "next/router";

import irohClient from "apis/iroh/irohClient";
import mergeDeep from "utils/mergeDeep";
import {trackLogin, trackLogout} from "utils/telemetry";
import {logger} from "utils/logger";

import {NEW_INCIDENTS_TOAST_SEEN_KEY} from "../components/AiAssistant/toast/AiToastIncidents";

import {applicationNames} from "./deployments";
import {hasImpersonationsDB} from "./impersonationDB";

import type {IrohProfileAccountsResponse} from "apis/iroh/profile/accounts";
import type {IrohAuthLoginsResponse} from "apis/iroh/iroh-auth/logins";
import type {AppKey, IrohProfileWhoamiResponse} from "apis/iroh/profile/whoami";
import type {Camelize, CamelizedAxiosResponse} from "types/camelize";

const AUTH_TOKEN_KEY = ":iroh-auth-token";
const REFRESH_TOKEN_KEY = ":iroh-refresh-token";
const SESSION_INFO_KEY = ":iroh-session-info";
const REDIRECT_AFTER_LOGIN_KEY = ":xdr-redirect-after-login";
const CODE_QS_PARAM = "code";
const READONLY_AUTH_TOKEN = ":iroh-readonly-auth-token";
const READONLY_REFRESH_TOKEN = ":iroh-readonly-refresh-token";

export type WindowShellApplicationType = "secure-client" | "xdr";

export function getLocalAuthToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function getLocalRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export const setAuthToken = (token: string) =>
  window.localStorage.setItem(AUTH_TOKEN_KEY, token);

export const setRefreshToken = (token: string) =>
  window.localStorage.setItem(REFRESH_TOKEN_KEY, token);

export const setRedirectAfterLogin = (url: string) =>
  window.sessionStorage.setItem(REDIRECT_AFTER_LOGIN_KEY, url);

export const getRedirectAfterLogin = () =>
  window.sessionStorage.getItem(REDIRECT_AFTER_LOGIN_KEY);

export const removeRedirectAfterLogin = () =>
  window.sessionStorage.removeItem(REDIRECT_AFTER_LOGIN_KEY);

export const getReadOnlyAuthSessionToken = () =>
  window.sessionStorage.getItem(READONLY_AUTH_TOKEN);

export const getReadOnlyRefreshSessionToken = () =>
  window.sessionStorage.getItem(READONLY_REFRESH_TOKEN);

export function getCodeParam() {
  const url = new URL(location.href);
  return url.searchParams.get(CODE_QS_PARAM);
}

export const postUserTokens = (userId: string) => {
  return irohClient.post(`/profile/get-user-tokens/${userId}`, {
    "refresh-token": getLocalRefreshToken(),
  });
};

/**
 * Exchanges a PKCE code for an access token.
 * @param code The PKCE code returned by the auth server.
 * @returns The access token.
 */
export function postAuthCode(
  code: string,
): Promise<{data: {accessToken: string}}> {
  return irohClient
    .post("/iroh-auth/code", {code})
    .then((resp) => {
      const {accessToken, refreshToken} = resp.data;

      localStorage.setItem(AUTH_TOKEN_KEY, accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

      trackLogin();

      return resp;
    })
    .catch((err) => {
      logger.error(
        "There was a problem exchanging the code for an access token.",
        err,
      );
      throw err;
    });
}

// This function assists in testing by setting app to secure-client or xdr for localhost
export function getApplicationLoaded(
  me: Camelize<IrohProfileWhoamiResponse>,
): AppKey {
  const params = new URL(window.location.href).searchParams;
  const application = params.get("app");

  if (window.location.hostname.startsWith("xdr")) {
    return "xdr";
  } else if (window.location.hostname.startsWith("secure-client")) {
    return "sc";
  }
  // prevent app param from working in PROD regions
  else if (
    application &&
    (application === "sc" || application === "sx" || application === "xdr") &&
    (process.env.REGION?.startsWith("INT") || process.env.REGION === "TEST")
  ) {
    return application as AppKey;
  } else if (
    window.location.hostname.startsWith("localhost") ||
    (process.env.DEV_HASH &&
      window.location.hostname.startsWith(process.env.DEV_HASH)) ||
    window.location.hostname.startsWith("securex-ui-shell")
  ) {
    return me.org["mainApp"]!;
  } else {
    return "xdr";
  }
}

/**
 * Get the user's profile information, including IDP, org, and user info.
 */
export async function getProfileInfo() {
  try {
    const apis = window.__SHELL__.apis!;

    const [me, logins, accounts] = await Promise.all([
      irohClient.get<
        IrohProfileWhoamiResponse,
        CamelizedAxiosResponse<IrohProfileWhoamiResponse>
      >("/profile/whoami"),
      irohClient.get<
        IrohAuthLoginsResponse,
        CamelizedAxiosResponse<IrohAuthLoginsResponse>
      >("/iroh-auth/logins"),
      irohClient.get<
        IrohProfileAccountsResponse,
        CamelizedAxiosResponse<IrohProfileAccountsResponse>
      >("/profile/accounts"),
    ]);

    const userId = me.data.user.userId;
    const mainApp = me.data.org["mainApp"]!;
    const mainAppUrl = me.data.metas.apps![mainApp]!.url;
    const applicationLoaded = getApplicationLoaded(me.data);
    const secureClientEnabled = mainApp === "sc";

    // Redirect if user does not have access to Secure Client
    if (mainApp !== applicationLoaded) {
      if (["sx", "sc", "xdr"].includes(mainApp)) {
        logger.info(
          `You do not have access to ${applicationNames.get(applicationLoaded)}. You will be redirected to ${applicationNames.get(mainApp)}.`,
        );
        window.location.href = `${apis.iroh.base}/iroh-auth/login/sxso?origin=${mainAppUrl}&user_id=${userId}`;
      } else if (mainAppUrl) {
        logger.info(`Unknown main app key. Redirecting to main app URL.`);
        window.location.href = mainAppUrl;
      } else {
        logger.error(
          `Unknown main app key. Unable to redirect. Clearing session.`,
        );
        clearSession();
      }
    }

    window.__SHELL__.sessionUrls = logins.rawData[me.rawData.idp?.id] ?? {};
    window.__SHELL__.application = secureClientEnabled
      ? "secure-client"
      : "xdr";

    // Sets idp, org, user, and logins info for MFEs to use
    localStorage.setItem(
      SESSION_INFO_KEY,
      JSON.stringify({
        ...me.rawData,
        accounts: accounts.rawData,
        logins: logins.rawData,
      }),
    );

    return mergeDeep(me, {
      data: {
        application: applicationLoaded,
        accounts: accounts.data,
        logins: logins.data,
        isSecureClient: secureClientEnabled,
      },
    }) as Omit<CamelizedAxiosResponse<IrohProfileWhoamiResponse>, "data"> & {
      data: CamelizedAxiosResponse<IrohProfileWhoamiResponse>["data"] & {
        application: AppKey;
        accounts: Camelize<IrohProfileAccountsResponse>;
        logins: Camelize<IrohAuthLoginsResponse>;
        isSecureClient: boolean;
      };
    };
  } catch (error) {
    logger.error("There was a problem fetching profile info:", error);
    throw error;
  }
}

/**
 * Clears local JWT session and user info.
 */
function clearSession() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(SESSION_INFO_KEY);
  sessionStorage.removeItem(NEW_INCIDENTS_TOAST_SEEN_KEY); // clear new incidents toast flag. @see AiToastIncidents.tsx
  removeRedirectAfterLogin();
}

/**
 * Clears the user session and redirects back to SCSO login.
 */
export function goToLogin() {
  const bookmarks = window.__SHELL__.bookmarks!;
  const apis = window.__SHELL__.apis!;
  const isDev = process.env.NODE_ENV === "development";
  const isVercelPreview =
    process.env.VERCEL_CUSTOM_PREVIEW_SUFFIX &&
    location.origin.endsWith(process.env.VERCEL_CUSTOM_PREVIEW_SUFFIX);

  type ApplicationType = "xdr" | "sc";
  const application = window.__SHELL__.application as ApplicationType;
  const urlIndex = window.location.href.includes("xdr") ? "xdr" : "sc";
  // use URL if application missing, default to XDR for CI
  const bookmark =
    bookmarks.login[application] ||
    bookmarks.login[urlIndex] ||
    bookmarks.login.xdr;

  clearSession();
  setRedirectAfterLogin(location.href);

  window.location.href =
    isDev || isVercelPreview
      ? `${apis.iroh.base}/iroh-auth/login/sxso?origin=${location.href}`
      : bookmark;
}

/**
 * Clears local user session, logs out of IDP, and goes back to SCSO login.
 */
export async function goToLogout(force = false) {
  const hasImpersonations = await hasImpersonationsDB();
  const redirect = () => {
    trackLogout();
    const sessionUrls = window.__SHELL__.sessionUrls!;
    window.location.href = sessionUrls.logout;
  };

  clearSession();

  try {
    // If impersonating a user, redirect back to impersonations page
    if (hasImpersonations && !force) {
      if (window.location.pathname != "/impersonations") {
        window.location.href = "/impersonations";
      }
    } else {
      redirect();
    }
  } catch (err) {
    logger.error("There was a problem while going to logout:", err);
    redirect();
  }
}

/**
 * Removes the `code` query param from the URL if it exists
 * and redirects to the URL without the `code` param. Either
 * to stored pre-login URL if exists or to no-code URL.
 */
export async function cleanupCodeParamAndRedirect() {
  const currentUrl = new URL(location.href);
  const code = currentUrl.searchParams.get(CODE_QS_PARAM);

  if (code) {
    const redirectHref = getRedirectAfterLogin();
    if (redirectHref) {
      removeRedirectAfterLogin();
      const redirectURL = new URL(redirectHref);
      redirectURL.searchParams.delete(CODE_QS_PARAM);
      return router.replace(redirectURL.href);
    } else {
      // fallback for case when someone stars authorization directly on login page
      currentUrl.searchParams.delete(CODE_QS_PARAM);
      return router.replace(currentUrl.href);
    }
  }
}

/**
 * Verifies the user session is invalid before re-auth.
 * @returns Whether the user will be logged out
 */
export async function requestLogout() {
  try {
    const user = await irohClient.get("/session/session-status");
    if (user) {
      // User will not be logged out
      return false;
    }
  } catch (err) {
    setTimeout(goToLogin, 250);
    // User will be logged out
    return true;
  }
}
