import {useEffect, useRef} from "react";

import type {
  EventHandler,
  Predicate,
  Subscription,
  XDREvent,
} from "@xdr-ui/event-client";

type UnknownEvent = XDREvent<string, unknown>;

/**
 * Type definition for a subscribe function that comes from a MFE specific client
 */
type SubscribeFn<T extends string, E extends XDREvent<T, unknown>> = (
  handler: EventHandler<E>,
  predicate?: Predicate<E>,
) => Subscription | undefined;

/** Generic subscribe function */
type UnknownSubscribeFn = SubscribeFn<string, UnknownEvent>;

/**
 * A helper type to infer the event type from a subscribe function
 */
export type SubscribeEvent<S extends UnknownSubscribeFn> = S extends (
  subscriber: (event: infer E) => void,
) => void
  ? E
  : never;

/**
 * A helper type to infer the topic type from a subscribe function
 */
type SubscribeTopic<S extends UnknownSubscribeFn> = SubscribeEvent<S>["topic"];

/**
 * A hook to subscribe to an event of a specific topic
 */
export function useEventCallback<
  S extends SubscribeFn<string, UnknownEvent>,
  T extends SubscribeTopic<S>,
>(
  subscribe: S,
  topic: T,
  callback: (e: Extract<SubscribeEvent<S>, {topic: T}>) => void,
) {
  const argsRef = useRef({subscribe, topic, callback});
  argsRef.current = {subscribe, topic, callback};

  useEffect(() => {
    const subscription = argsRef.current.subscribe(
      (event) => {
        argsRef.current.callback(
          event as Extract<SubscribeEvent<S>, {topic: T}>,
        );
      },
      (event) => event.topic === argsRef.current.topic,
    );
    return () => subscription?.unsubscribe();
  }, []);
}
