export interface PatternReplacement {
  pattern: RegExp | string;
  replacement: string;
}

export function replaceAllPatterns(
  value: string,
  patterns: PatternReplacement[],
): string {
  return patterns.reduce<string>(
    (acc, replacementPattern) =>
      acc.replaceAll(
        replacementPattern.pattern,
        replacementPattern.replacement,
      ),
    value,
  );
}
