import {forwardRef} from "react";
import {useTranslation} from "react-i18next";

import {CDSHeader} from "@ciscodesignsystems/cds-react-header";
import {CDSLogoCisco} from "@ciscodesignsystems/cds-react-logos";

import AiAssistant from "components/AiAssistant";
import useAuth from "hooks/useAuth";

import HelpMenu from "./HelpMenu";
import TenantMenu from "./TenantMenu";

import styles from "./Header.module.scss";

export default forwardRef(function Header(_, ref) {
  const {t} = useTranslation();
  const {isSecureClient} = useAuth();
  return (
    <>
      <CDSHeader
        ref={ref}
        // Currently used by incidents-app for drawers positioning
        id="header"
        logo={
          <span className={styles.logo}>
            <CDSLogoCisco />
          </span>
        }
        logoLink={null} // TODO: Get this to work, but with next/link
        sentiment="inverse"
        title={!isSecureClient ? t("app.name.xdr") : t("app.name.secureClient")}
        className={styles.header}
      >
        {!isSecureClient && (
          <div className={styles.headerButtons}>
            <AiAssistant />
            <HelpMenu />
            <div id="xdr-header-integrations" />
          </div>
        )}
        {isSecureClient && (
          <div className={styles.headerButtons}>
            <HelpMenu />
          </div>
        )}
        <TenantMenu />
      </CDSHeader>
      <div className={styles.spacer} />
    </>
  );
});
