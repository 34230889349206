import {createContext, useCallback, useEffect} from "react";
import {v4 as uuid} from "uuid";

import type {
  CDSToastObject,
  CDSToasterStatus,
} from "@ciscodesignsystems/cds-react-toaster";
import {CDSToaster, useToast} from "@ciscodesignsystems/cds-react-toaster";

import {logger} from "utils/logger";
import {useAggregatedEventCallback} from "eventBus/hooks/useAggregatedEventCallback";
import {DEFAULT_TOAST_TIMEOUT} from "components/AiAssistant/toast/utils";
import styles from "components/AiAssistant/toast/AiToast.module.scss";

const STORAGE_KEY = "delayed-notification";
const MIN_NOTIFICATION_TIME = 5_000;
const MAX_NOTIFICATION_VALIDITY = 10_000;

type ToastObject = Omit<CDSToastObject, "status"> & {
  status: CDSToasterStatus | "ai";
};
export type EnqueueToast = (toast: ToastObject) => void;
export type DequeueToast = (id: string) => void;

function isToastObject(data: unknown): data is ToastObject {
  return (
    typeof data === "object" &&
    data !== null &&
    "id" in data &&
    typeof data.id == "string"
  );
}

function isValidToastData(
  data: unknown,
): data is {timestamp: number; toast: ToastObject} {
  return (
    typeof data === "object" &&
    data !== null &&
    "toast" in data &&
    "timestamp" in data &&
    typeof data.timestamp === "number" &&
    isToastObject(data.toast)
  );
}

interface ToastContext {
  queue: CDSToastObject[];
  enqueueToast: EnqueueToast;
  dequeueToast: DequeueToast;
}

export const ToastContext = createContext<ToastContext>({
  queue: [],
  enqueueToast: () => {},
  dequeueToast: () => {},
});

interface ToastProviderProps {
  children: React.ReactNode;
}

export default function ToastProvider({children}: ToastProviderProps) {
  const {toasts, addToast, deleteToast} = useToast();

  const enqueueToast: EnqueueToast = useCallback(
    ({id = uuid(), status, ...config}) => {
      const toast: CDSToastObject =
        /**
         * All status `ai` are equivalent to `info` status with a different ui/icon
         * Info notifications have a default timeout of 6000ms, if not provided in the config.
         * https://magnetic.cisco.com/0a43ab5cd/p/3912cf-notifications/t/73b426
         */
        status === "ai"
          ? {
              id: `ai-xdr-toast-${id}`,
              status: "info",
              ...{
                ...config,
                timeout: config.timeout ?? DEFAULT_TOAST_TIMEOUT.INFO,
              },
            }
          : {id, status, ...config};

      addToast(toast);

      /**
       * If a notification message should be displayed and the page is left in the meantime
       * the notification is stored into the session storage. Then it is restored when the app
       * is loaded again
       */
      const onVisibilityChange = () => {
        window.sessionStorage.setItem(
          STORAGE_KEY,
          JSON.stringify({toast, timestamp: Date.now()}),
        );
      };
      window.addEventListener("visibilitychange", onVisibilityChange);
      setTimeout(() => {
        window.removeEventListener("visibilitychange", onVisibilityChange);
      }, toast.timeout ?? MIN_NOTIFICATION_TIME);

      return toast.id;
    },
    [addToast],
  );

  const dequeueToast: DequeueToast = useCallback(
    (id) => {
      deleteToast(id);
    },
    [deleteToast],
  );

  /**
   * Restore notification from the last time
   */
  useEffect(() => {
    try {
      const previousNotification = window.sessionStorage.getItem(STORAGE_KEY);
      if (previousNotification) {
        const toastData = JSON.parse(previousNotification) as unknown;

        if (!isValidToastData(toastData)) {
          return;
        }

        const {toast, timestamp} = toastData;
        if (Date.now() - timestamp > MAX_NOTIFICATION_VALIDITY) {
          return;
        }

        addToast(toast as CDSToastObject);
      }
    } catch (e) {
      // nothing to do
      logger.error("Failed to restore notification", e);
    } finally {
      // cleanup
      window.sessionStorage.removeItem(STORAGE_KEY);
    }
  }, [addToast]);

  useAggregatedEventCallback("enqueueToast", ({payload}) => {
    enqueueToast(payload);
  });

  useAggregatedEventCallback("dequeueToast", ({payload}) => {
    dequeueToast(payload.id);
  });

  useEffect(() => {
    // TODO: set the window utils here
    window.__SHELL__.utils = window.__SHELL__.utils ?? {};
    window.__SHELL__.utils.toast = {
      enqueue: enqueueToast,
      dequeue: dequeueToast,
    };
  }, [enqueueToast, dequeueToast]);

  return (
    <ToastContext.Provider
      value={{
        queue: toasts,
        enqueueToast,
        dequeueToast,
      }}
    >
      {children}
      <div className={styles.xdrToast}>
        <CDSToaster toastList={toasts} placement="top-right" />
      </div>
    </ToastContext.Provider>
  );
}
