/**
 * Makes an object and all of its properties fully immutable.
 * Taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
 *
 * @param {*} object Object to freeze
 * @returns immutable object
 */
export function freezeDeep(object) {
  // Retrieve the property names defined on object
  const propNames = Reflect.ownKeys(object);

  // Freeze properties before freezing self
  for (const name of propNames) {
    const value = object[name];

    if ((value && typeof value === "object") || typeof value === "function") {
      freezeDeep(value);
    }
  }

  return Object.freeze(object);
}
