export interface IncidentSearchParams {
  from: string;
  query: string[];
  status: string[];
}

export const incidentSearchParams: IncidentSearchParams = {
  from: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
  query: ["_exists_:scores AND !assignees:(*) AND scores.global:[700 TO *]"],
  status: ["New"],
};

export const filteredIncidentsUrl =
  "/incidents?assigneeFilter=Unassigned&statusFilter=New&priorityScoreFilter=700&order=desc&orderBy=scores.global&dateRangeFilter=LAST30DAYS";

/**
 * Magnetic defines the default timeout for different toast statuses.
 * https://magnetic.cisco.com/0a43ab5cd/p/3912cf-notifications/t/73b426
 */
export const DEFAULT_TOAST_TIMEOUT = {
  INFO: 6000,
  POSITIVE: 6000,
  WARNING: 8000,
};
