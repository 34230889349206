import {useEffect} from "react";

interface BroadcastChannelEvent<P = object> {
  type: string;
  payload: P;
  source: string;
}

export default function useBroadcastChannel<P = object>(
  handler: (e: BroadcastChannelEvent<P>) => void,
) {
  useEffect(() => {
    const channel = new BroadcastChannel("xdr");

    function handleBroadcast(event: MessageEvent) {
      if (event.isTrusted) {
        handler(event.data);
      }
    }

    channel.addEventListener("message", handleBroadcast);

    return () => {
      channel.removeEventListener("message", handleBroadcast);
    };
  }, [handler]);
}
