import classnames from "classnames";
import {Moon, Sun} from "phosphor-react";

import useTheme from "hooks/useTheme";

import styles from "./ThemeIcon.module.scss";

export default function ThemeIcon() {
  const {theme} = useTheme();

  return (
    <div className={styles.themeIcon}>
      <Sun
        weight="regular"
        size={24}
        className={classnames([
          "grey-text-color",
          styles.sun,
          theme === "default" && styles.active,
          theme !== "default" && styles.inactive,
        ])}
      />
      <Moon
        weight="regular"
        size={24}
        className={classnames([
          "grey-text-color",
          styles.moon,
          theme === "dusk" && styles.active,
          theme !== "dusk" && styles.inactive,
        ])}
      />
    </div>
  );
}
