import {Trans, useTranslation} from "react-i18next";

import {CDSButton} from "@ciscodesignsystems/cds-react-button";
import {CDSModal} from "@ciscodesignsystems/cds-react-modal";

import {goToLogin} from "utils/auth";

import styles from "./RoleChangedModal.module.scss";

interface RoleChangedModalProps {
  error: string;
}

export default function RoleChangedModal({error}: RoleChangedModalProps) {
  const {oldRole, newRole} = useExtractedRolesFromError(error);
  return (
    <CDSModal isOpen={Boolean(error)} size="lg" className={styles.modal}>
      <div className={styles.body}>
        <div className={styles.title}>Your Role Has Changed</div>
        <Trans
          i18nKey="app.roleChange.info"
          components={{
            br: <br />,
            b: <b />,
            p: <p />,
          }}
          values={{oldRole, newRole}}
        />
      </div>
      <div className={styles.footer}>
        <CDSButton onClick={goToLogin}>Refresh</CDSButton>
      </div>
    </CDSModal>
  );
}

function useExtractedRolesFromError(error = "") {
  const {t} = useTranslation();

  const roleLookup: Record<string, string> = {
    admin: t("roles.admin"),
    user: t("roles.user"),
    sat: t("roles.sat"),
  } as const;

  const [_, oldRoleAbbr, newRoleAbbr] =
    error?.match(/Token role: (\w+), DB role: (\w+)/) ?? [];

  return {
    oldRole: roleLookup[oldRoleAbbr],
    newRole: roleLookup[newRoleAbbr],
  };
}
