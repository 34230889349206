import {useRouter} from "next/router";
import {ErrorBoundary} from "react-error-boundary";

import {logger} from "utils/logger";

import ErrorFallback from "./ErrorFallback";

export default function RemoteErrorBoundary({children}) {
  const router = useRouter();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={handleError}
      onReset={router.reload}
      resetKeys={[router.asPath]}
    >
      {children}
    </ErrorBoundary>
  );
}

function handleError(error, info) {
  logger.error("RemoteErrorBoundary error", {error, info});
}
