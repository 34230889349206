import type {CDSNotificationStatus} from "@ciscodesignsystems/cds-react-notification";

import type {BannerItem, BannerStates} from "types/bannerState";
import type {User} from "context/AuthProvider";

// Constants for TDR event type to status mapping
const EVENT_TYPE_TO_STATUS_MAP = new Map<string, CDSNotificationStatus>([
  ["major", "negative"],
  ["minor", "warning"],
  ["performance", "warning"],
  ["maintenance", "info"],
]);

// Constants for TDR region to XDR region mapping
const TDR_REGION_TO_XDR_REGION_MAP = new Map([
  ["Australia", "PROD_APJC"],
  ["Asia", "PROD_APJC"],
  ["India", "PROD_APJC"],
  ["Europe", "PROD_EU"],
  ["North America", "PROD_US"],
]);

// Constants for TDR Product to XDR scope mapping
const TDR_PRODUCT_TO_XDR_SCOPE_MAP = new Map([
  ["cisco/feature-flag/xdr", "XDR"],
  ["cisco/feature-flag/xdr:read", "XDR"],
  ["cisco/feature-flag/xdr:write", "XDR"],
]);

// Maps event type to CDS notification status
export const mapEventTypeToStatus = (
  eventType: string = "maintenance",
): CDSNotificationStatus => {
  return EVENT_TYPE_TO_STATUS_MAP.get(eventType) ?? "info";
};

// if the banner item region name is in the XDR region map, filter it by the user's region, else don't filter
export const filterItemsByRegion =
  (region: string) => (bannerItem: BannerItem) => {
    const filterableXdrRegions = [...TDR_REGION_TO_XDR_REGION_MAP.values()];

    const regionStartsWithXdrMappedRegion = filterableXdrRegions.some(
      (mappedXdrRegion) => region?.startsWith(mappedXdrRegion),
    );
    return regionStartsWithXdrMappedRegion
      ? bannerItem?.components?.some((component) =>
          region?.startsWith(
            TDR_REGION_TO_XDR_REGION_MAP.get(component?.region?.name) ?? "",
          ),
        )
      : true;
  };

export const filterByNotClosedInStorage =
  (storedBanners: BannerStates[]) => (banner: BannerItem) => {
    const finder = storedBanners.find(
      (storedBanner) => storedBanner.id === banner.id,
    );
    if (finder?.closed === undefined || finder?.closed === false) {
      return true;
    } else {
      return false;
    }
  };

export const filterItemsByScope = (user: User) => (bannerItem: BannerItem) => {
  const bannerItemProductName = bannerItem?.product?.name;

  const filterableTdrProducts = [...TDR_PRODUCT_TO_XDR_SCOPE_MAP.values()];

  // If the TDR product name exists as a value in our map we need to filter it if it doesn't map to a user's scope
  // If the bannerItemProductName does not exist as a value in our map return true as we shouldn't filter that banner product
  return filterableTdrProducts.some(
    (tdrProductName) => tdrProductName === bannerItemProductName,
  )
    ? user?.scopes?.some(
        (scope) =>
          TDR_PRODUCT_TO_XDR_SCOPE_MAP.get(scope) === bannerItemProductName,
      )
    : true;
};
