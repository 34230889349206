import {ClientLogger} from "./env/client";
import {ServerLogger} from "./env/server";

export type LogMethod = (message: string, meta?: unknown) => void;

export interface Logger {
  error: LogMethod;
  warn: LogMethod;
  info: LogMethod;
  debug: LogMethod;
}

const logger =
  typeof window === "undefined" ? new ServerLogger() : new ClientLogger();

export {logger};
