import {useEffect, useState} from "react";

import styles from "./Spinner.module.scss";

export default function Spinner({delay = 2000}) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setVisible(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return (
    visible && (
      <div className={styles.wrapper}>
        <div className={styles.spinner} />
      </div>
    )
  );
}
